import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deleteConfig, getConfigs } from "api/request.api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";

function ConfigIndex() {
    const navigate = useNavigate()
    const columns = useMemo(() => [
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Label",
            accessor: "label",
        },
        {
            Header: "Value",
            accessor: "value",
        },
        {
            Header: "status",
            accessor: "status",
            Cell: ({ cell }) => (cell.value ? "Active" : "Inactive"),
        },
        {
            Header: "Actions",
            accessor: "_id",
            Cell: ({ cell }) => (
                <>
                    <Button
                        type="button"
                        variant="info"
                        className="ms-2"
                        icon="bi bi-pencil-square"
                        onClick={() => navigate(`/admin/config/edit/${cell.value}`)}
                    />
                </>
            ),
        },
    ]);

    const [configs, setConfigs] = useState([]);

    async function fetchConfigs() {
        const { data } = await getConfigs();
        setConfigs(data);
    }

    const handleDeleteConfig = async (configId) => {
        const data = await deleteConfig(configId);
        if (data.success) {
            fetchConfigs();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this config?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteConfig(id);
            }
        });
    };

    useEffect(() => {
        fetchConfigs();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Configs List" />
            <PageContent>
                <Table columns={columns} data={configs} filter={false}/>
            </PageContent>
        </div>
    );
}

export default ConfigIndex;

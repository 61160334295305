import { RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import route from "./routes/root";
import { NotificationContextProvider } from "context/NotificationContext";
import { UploadContextProvider } from "context/UploadContext";

const App = () => {
    return (
        <UploadContextProvider>
            <NotificationContextProvider>
                <RouterProvider router={route}/>
                <ToastContainer />
            </NotificationContextProvider>
        </UploadContextProvider>
    );
};

export default App;

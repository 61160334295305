import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deleteBlog, getBlogs } from "api/request.api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";

function Index() {
    const columns = useMemo(() => [
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Thumbnail",
            accessor: "thumbnail",
            Cell: ({ cell }) => (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/${cell.value}`}
                    alt="User"
                    width="100"
                />
            ),
        },
        {
            Header: "Actions",
            accessor: "slug",
            Cell: ({ cell }) => (
                <>
                    <Button
                        type="button"
                        variant="info"
                        className="ms-2"
                        icon="bi bi-pencil-square"
                        onClick={() =>
                            navigate(`/admin/blog/edit/${cell.value}`)
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="ms-2"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />
                </>
            ),
        },
    ]);

    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    async function fetchBlogs() {
        const { data } = await getBlogs();
        setBlogs(data);
    }

    const handleDeleteBlog = async (id) => {
        const data = await deleteBlog(id);
        if (data.success) {
            fetchBlogs();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this blog?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteBlog(id);
            }
        });
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Blogs List" />
            <PageContent>
                <div className="d-flex flex-row-reverse">
                    <Link
                        className="btn btn-primary"
                        role="button"
                        to="/admin/blog/create"
                    >
                        Create
                    </Link>
                </div>
                <Table columns={columns} data={blogs} filter={true} />
            </PageContent>
        </div>
    );
}

export default Index;

import Joi from "joi";

const createVariantSchema = Joi.object({
    name: Joi.string().required(),
    offerPrice: Joi.number().optional(),
    price: Joi.number().positive().required(),
    offerPrice: Joi.number().less(Joi.ref("price")).required(),
    offerStart: Joi.when("offerPrice", {
        is: Joi.not("").required(),
        then: Joi.date().min(new Date().toISOString().split("T")[0]).raw(),
        otherwise: Joi.optional(),
    }),
    offerEnd: Joi.when("offerPrice", {
        is: Joi.not("").required(),
        then: Joi.date().min(Joi.ref("offerStart")).required(),
        otherwise: Joi.optional(),
    }),
    stock: Joi.number().min(0).required(),
    order: Joi.number().positive(),

    height: Joi.string().required(),
    width: Joi.string().required(),
    depth: Joi.string().required(),

    weight: Joi.string(),
    sku: Joi.string().required(),
    sizeId: Joi.string(),
}).options({ abortEarly: false });

export default createVariantSchema;
import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { createAdmin, deleteAdminUser, getUsers } from "api/request.api";
import { Link } from "react-router-dom";
import { Button, Input } from "components";
import Swal from "sweetalert2";
import Joi from "joi";
import validateField from "helpers/validateData";
import { toast } from "react-toastify";

function UserIndex() {
    const columns = useMemo(() => [
        {
            Header: "Full name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Role",
            accessor: "role",
        },
        {
            Header: "Account Type",
            accessor: "type",
            Cell: ({ row }) => (
                <>
                    {row?.original?.googleId
                        ? "Google"
                        : row?.original?.facebookId
                        ? "Facebook"
                        : "Email"}
                </>
            ),
        },
        {
            Header: "Action",
            accessor: "_id",
            Cell: ({ cell,row }) => (
                <>
                    {row?.original?.role === "admin" 
                        && <Button
                        type="button"
                        variant="danger"
                        className="ms-2"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />}
                </>
            ),
        },

    ]);

    const [users, setUsers] = useState([]);
    const [error, setError] = useState({});
    const [data, setData] = useState({
        name: "",
        email: "",
    });

    const fetchUsers = async () => {
        const { data } = await getUsers();
        setUsers(data);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const schema = Joi.object({
        name: Joi.string().required().label("Title"),
        email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
            .label("Email"),
    }).options({ abortEarly: false });

    const handleDeleteAdmin = async (id) => {
        const data = await deleteAdminUser(id);
        if (data.success) {
            fetchUsers();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this admin?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteAdmin(id);
            }
        });
    };


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleCreateAdmin = async () => {
        const fieldErrors = validateField(
            schema,
            data
        );
        setError(fieldErrors);

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }
        const result = await createAdmin(data);

        if (result.success) {
            setData({
                name: "",
                email: "",
            })
            fetchUsers()
        } else {
            toast.error(result?.data?.message);
        }
    };

    return (
        <div className="p-4">
            <PageHead title="Users List" />
            <div className="row">
                <div className="col-sm-4">
                    <Input
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        label="Name:"
                        value={data.name}
                        onChange={handleOnChange}
                        error={error.name}
                    />
                </div>
                <div className="col-sm-4">
                    <Input
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        label="Email:"
                        value={data.email}
                        onChange={handleOnChange}
                        error={error.email}
                    />
                </div>
                <div className="col-sm-4 pt-2">
                    <button
                        className="btn btn-primary rounded-1 mt-4"
                        onClick={handleCreateAdmin}
                    >
                        Save Changes
                    </button>
                </div>
            </div>

            <PageContent>
                <Table columns={columns} data={users}/>
            </PageContent>
        </div>
    );
}

export default UserIndex;

import {
    createProduct,
    createVariant,
    deleteVariant,
    editProduct,
    getAllCategories,
    getProduct,
} from "api/request.api";
import Editor from "components/Editor";
import Input from "components/Input";
import Select from "components/Select";
import FileUploader from "components/FileUploader";
import React, { useEffect, useRef, useState } from "react";

import Variant from "./Variant";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Button } from "components";
import createForm from "helpers/createForm";

function Edit() {
    const [allCategories, setAllCategories] = useState([]);

    const [existingImage, setExistingImage] = useState("");
    const [thumbnailPreview, setThumbnailPreview] = useState();

    const [productReadOnly, setProductReadOnly] = useState(false);
    const [variantsReadOnly, setVariantsReadOnly] = useState([]);

    const productId = useParams().id;

    const [basicInfo, setBasicInfo] = useState({
        name: "",
        delivery: "",
        returnPolicy: "",
        summary: "",
        description: "",
        thumbnail: "",
    });
    const [parentCategory, setParentCategory] = useState("");
    const [variants, setVariants] = useState([]);
    const editorRef = useRef(null);

    const fetchProduct = async () => {
        const { data } = await getProduct(productId);

        setBasicInfo({
            name: data.name,
            delivery: data.delivery,
            returnPolicy: data.returnPolicy,
            summary: data.summary,
            description: data.description,
            // variant: data.variant
        });

        setParentCategory({
            value: data.category._id,
            label: data.category.name,
        });
        setExistingImage(data.thumbnail);
        setVariants(data.variant)

    };

    const fetchAllCategories = async () => {
        const { data } = await getAllCategories();
        setAllCategories(data);
    };

    const handleChange = (e) => {
        const { name, type } = e.target;
        let { value } = e.target;

        if (type === "file" && value) {
            value = e.target.files[0];
            setThumbnailPreview(URL.createObjectURL(value));
        }

        setBasicInfo({
            ...basicInfo,
            [name]: value,
        });
    };

    const handleCategoryChange = (element) => {
        setParentCategory(element);
    };

    useEffect(() => {
        fetchAllCategories();
        fetchProduct();
    }, []);

    useEffect(() => {
        if (thumbnailPreview) {
            return () => URL.revokeObjectURL(thumbnailPreview);
        }
    }, [thumbnailPreview]);

  
  

    const handleSubmit = async () => {
        const product = {
            ...basicInfo,
            description: editorRef.current.getContent(),
            category: parentCategory.value,
        };

        const result = await editProduct(productId, createForm(product));
        if (result.success) {
            setProductReadOnly(true);
            setThumbnailPreview(null);
            setExistingImage(result.data.thumbnail);
        }
    };

 
    return (
        <div className="p-4">
            <section>
                <div className="row">
                    <div className="col-sm-6 mb-3">
                        <h4 className="fw-semibold">Add Product</h4>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="#">Products</a>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                >
                                    Edit
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="p-4 mb-3 bg-white rounded-3">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold my-0">
                            Basic Product Information
                        </h6>
                        <p>Fill all information below</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6">
                            <Input
                                id="name"
                                name="name"
                                placeholder="Product name"
                                label="Product name:"
                                type="text"
                                value={basicInfo.name}
                                onChange={handleChange}
                                readOnly={productReadOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Select
                                id="parentCategory"
                                label="Parent Category:"
                                name="category"
                                data={allCategories.map((el) => {
                                    return { value: el._id, label: el.name };
                                })}
                                readOnly={productReadOnly}
                                onChange={handleCategoryChange}
                                default={parentCategory}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="delivery"
                                name="delivery"
                                placeholder="Delivery"
                                label="Delivery:"
                                type="type"
                                value={basicInfo.delivery}
                                onChange={handleChange}
                                readOnly={productReadOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="returnPolicy"
                                name="returnPolicy"
                                placeholder="Return Policy"
                                readOnly={productReadOnly}
                                value={basicInfo.returnPolicy}
                                onChange={handleChange}
                                label="Return Policy:"
                            />
                        </div>
                        <div className="col-sm-6">
                            <FileUploader
                                id="thumbnail1"
                                label="Thumbnail:"
                                disabled={productReadOnly}
                                multiple={false}
                                name="thumbnail"
                                value={basicInfo.thumbnail}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            {thumbnailPreview && (
                                <>
                                    <span className="fw-semibold">
                                        Preview Thumbnail
                                    </span>
                                    <br />
                                    <img
                                        src={thumbnailPreview}
                                        height={100}
                                        width={100}
                                    />
                                </>
                            )}
                        </div>
                        <div className="col-sm-6">
                            <>
                                <span className="fw-semibold">
                                    Existing Thumbnail:
                                </span>
                                <br />
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${existingImage}`}
                                    height={100}
                                    width={100}
                                />
                            </>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <label
                                htmlFor="summary"
                                className="form-label fw-semibold"
                            >
                                Summary:
                            </label>
                            <textarea
                                className="form-control"
                                id="summary"
                                rows="3"
                                name="summary"
                                value={basicInfo.summary}
                                onChange={handleChange}
                                readOnly={productReadOnly}
                            ></textarea>
                        </div>

                        <div className="col-sm-12 mb-3">
                            <Editor
                                id="description"
                                label="Description:"
                                editorRef={editorRef}
                                initialValue={basicInfo.description}
                                readOnly={productReadOnly}
                            />
                        </div>
                    </div>

                    <Button
                        type="button"
                        onClick={
                            productReadOnly
                                ? () => setProductReadOnly(false)
                                : handleSubmit
                        }
                        label={productReadOnly ? "Edit" : "Update"}
                        variant="primary"
                    />
                </form>
            </section>

            <div className="accordion accordion-flush" id="variantAccordian">
                {/* {variantRefs.current.map((ref, index) => (
                    <Variant
                        key={variantCount[index]}
                        index={index + 1}
                        data={variantCount[index]}
                        variantRef={ref}
                        onAddMore={handleOnVariantAddMore}
                        onDiscard={handleOnVariantDiscard}
                        onDelete={onDeleteVariant}
                        readOnly={variantsReadOnly[index]}
                        productId={productId}
                        toggle={toggleVariantReadOnly}
                    />
                ))} */}
            </div>
        </div>
    );
}

export default Edit;

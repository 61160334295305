import { getNotificationStat } from "api/request.api";
import React, { useEffect, useState, createContext } from "react";

const NotificationContext = createContext();
export default NotificationContext;

export function NotificationContextProvider({ children }) {
    const [notification, setNotification] = useState({
        contact: 0,
        enquiry: 0,
        order: 0,
    });

    const fetchNotificationStat = async () => {
        const { data } = await getNotificationStat();
        setNotification(data);
    };

    useEffect(() => {
        fetchNotificationStat();
    }, []);

    return (
        <NotificationContext.Provider value={{ notification, setNotification }}>
            {children}
        </NotificationContext.Provider>
    );
}

import Table from "components/Table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import {  getContacts, markContactAsRead, markContactAsUnread } from "api/request.api";
import { useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";
import NotificationContext from "context/NotificationContext";

function Index() {
    const navigate = useNavigate();
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Subject",
            accessor: "subject",
        },
        {
            Header: "Mark as Read",
            accessor: "isRead",
            Cell: ({ cell, row }) => (
                <Button
                    type="button"
                    variant={cell.value ? "secondary" : "primary"}
                    icon={
                        cell.value
                            ? "bi bi-envelope-open-fill"
                            : "bi bi-envelope-fill"
                    }
                    title={cell.value ? "Mark as unread" : "Mark as read"}
                    onClick={() =>
                        cell.value
                            ? markAsUnread(row.original._id)
                            : markAsRead(row.original._id)
                    }
                />
            ),
        },
        {
            Header: "Actions",
            accessor: "message",
            Cell: ({ cell, row }) => (
                <Button
                    type="button"
                    variant="primary"
                    className="ms-2"
                    icon="bi bi-eye"
                    label="View"
                    onClick={() => handleViewContact(cell.value, row.original._id)}
                />
            ),
        },
    ]);

    const [contacts, setContacts] = useState([]);
    const {notification, setNotification} = useContext(NotificationContext);

    async function fetchContacts() {
        const { data } = await getContacts();
        setContacts(data);
    }

    const updateContacts = (id, state) => {
        const index = contacts.findIndex((item) => item._id === id);
        const contactData = [...contacts];
        contactData[index].isRead = state;
        setContacts(contactData)
    }

    const markAsRead = async(id) => {
        await markContactAsRead(id);
        setNotification({
            ...notification,
            contact: notification.contact - 1
        })
        updateContacts(id, true)
    }

    const markAsUnread = async(id) => {
        await markContactAsUnread(id);
        setNotification({
            ...notification,
            contact: notification.contact + 1
        })
        updateContacts(id, false)
    }

    const handleViewContact = (message, id) => {
        Swal.fire({
            title: "Message",
            html: message,
            showCloseButton: true,
            width: "600px",
        });
        markAsRead(id);
    };

    useEffect(() => {
        fetchContacts();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Contact List" />
            <PageContent>
                <Table columns={columns} data={contacts} filter={true} />
            </PageContent>
        </div>
    );
}

export default Index;

import Table from "components/Table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import {
    deleteEnquiry,
    getEnquiry,
    markEnquiryAsRead,
    markEnquiryAsUnread,
} from "api/request.api";
import { useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";
import NotificationContext from "context/NotificationContext";

function EnquiryIndex() {
    const navigate = useNavigate();
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Variant",
            accessor: "variantId",
            Cell: ({ cell }) => {
                const size = cell?.value?.size;
                return (
                    <p style={{width: "170px"}}>
                        Name: {cell?.value?.name}
                        <br /> Size: {size?.depth} * {size?.width} * {size?.height}
                    </p>
                );
            },
        },
        {
            Header: "Mark as Read",
            accessor: "isRead",
            Cell: ({ cell, row }) => (
                <Button
                    type="button"
                    variant={cell.value ? "secondary" : "primary"}
                    icon={
                        cell.value
                            ? "bi bi-envelope-open-fill"
                            : "bi bi-envelope-fill"
                    }
                    title={cell.value ? "Mark as unread" : "Mark as read"}
                    onClick={() =>
                        cell.value
                            ? markAsUnread(row.original._id)
                            : markAsRead(row.original._id)
                    }
                />
            ),
        },
        {
            Header: "Actions",
            accessor: "message",
            Cell: ({ cell, row }) => (
                <>
                    <Button
                        type="button"
                        variant="primary"
                        className="me-2 mt-2"
                        icon="bi bi-eye"
                        label="View"
                        onClick={() =>
                            handleViewContact(cell.value, row.original._id)
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="mt-2"
                        icon="bi bi-trash"
                        label="Delete"
                        onClick={() => onDelete(row.original._id)}
                    />
                </>
            ),
        },
    ]);

    const [enquiry, setEnquiry] = useState([]);
    const { notification, setNotification } = useContext(NotificationContext);

    async function fetchEnquiry() {
        const { data } = await getEnquiry();
        setEnquiry(data);
    }

    const updateEnquiry = (id, state) => {
        const enquiryIndex = enquiry.findIndex((item) => item._id === id);
        const enquiries = [...enquiry];
        enquiries[enquiryIndex].isRead = state;
        setEnquiry(enquiries)
    }

    const markAsRead = async (id) => {
        await markEnquiryAsRead(id);
        setNotification({
            ...notification,
            enquiry: notification.enquiry - 1
        })
        updateEnquiry(id, true)
    };

    const markAsUnread = async (id) => {
        await markEnquiryAsUnread(id);
        setNotification({
            ...notification,
            enquiry: notification.enquiry + 1
        })
        updateEnquiry(id, false)
    };

    const handleViewContact = (message, id) => {
        Swal.fire({
            title: "Message",
            html: message,
            showCloseButton: true,
            width: "600px",
        });
        markAsRead(id);
    };

    const handleDeleteEnquiry = async (id) => {
        const data = await deleteEnquiry(id);
        if (data.success) {
            fetchEnquiry();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this enquiry record?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteEnquiry(id);
            }
        });
    };

    useEffect(() => {
        fetchEnquiry();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Enquiry List" />
            <PageContent>
                <Table columns={columns} data={enquiry} filter={true} />
            </PageContent>
        </div>
    );
}

export default EnquiryIndex;

import { logout } from "api/request.api";
import NotificationContext from "context/NotificationContext";
import UploadContext from "context/UploadContext";
import React, { useRef, useContext } from "react";
import {
    Link,
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";

function AdminLayout() {
    const sidebarRef = useRef();

    const toggleSidebar = () => {
        sidebarRef.current.classList.toggle("d-none");
    };

    const navigate = useNavigate();

    const handleOnLogout = async () => {
        await logout();
        localStorage.clear();
        navigate("/login");
    };

    function hasJWT() {
        let flag = false;

        flag = localStorage.getItem("isLogged") ?? false;

        return flag;
    }

    if (!hasJWT()) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <main className="d-flex flex-nowrap">
                <div
                    className="flex-shrink-0 p-3 bg-white"
                    style={{ width: "250px" }}
                    id="sidebar"
                    ref={sidebarRef}
                >
                    <a
                        href="/"
                        className="d-flex justify-content-center pb-3 mb-3 link-dark text-decoration-none border-bottom"
                    >
                        <span className="fs-5 fw-semibold h1">
                            Milushree Arts
                        </span>
                    </a>
                    <SideBar />
                </div>
                <div className="w-100">
                    <nav className="navbar bg-white">
                        <div className="container-fluid">
                            <a
                                className="navbar-brand border border-dark px-2"
                                href="#"
                                id="sidebar-toggler"
                                onClick={toggleSidebar}
                            >
                                <i className="bi bi-list fa-10x h3"></i>
                            </a>
                            <a
                                id="offcanvas-toggler"
                                className="navbar-brand border border-dark px-2"
                                data-bs-toggle="offcanvas"
                                href="#offcanvasSidebar"
                                role="button"
                                aria-controls="offcanvasSidebar"
                            >
                                <i className="bi bi-list h3"></i>
                            </a>
                            <div className="btn-group dropstart">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ borderRadius: "50%" }}
                                >
                                    U
                                </button>

                                <ul className="dropdown-menu rounded-0">
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/admin/users/changePassword"
                                        >
                                            Change Password
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            onClick={handleOnLogout}
                                        >
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <hr className="my-0" />
                    <div className="container-fluid bg-main h-100">
                        <Outlet />
                    </div>
                    {/* <!-- main content --> */}
                </div>
            </main>
            <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasSidebar"
                aria-labelledby="offcanvasSidebarLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasSidebarLabel">
                        Milushree Arts
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body offcanvas-menu">
                    <SideBar />
                </div>
            </div>
        </>
    );
}

export default AdminLayout;

const SideBar = () => {
    const { notification } = useContext(NotificationContext);
    const { isUploading } = useContext(UploadContext);

    const location = useLocation();
    const path = location.pathname;

    const getActiveNav = (substr) => {
        return path.endsWith(substr)
            ? " border border-secondary rounded-2"
            : "";
    };

    const handleRouteClick = (e) => {
        if(isUploading){
            toast.warn("Product is being uploading...")
            e.preventDefault()
        }
    };
    return (
        <ul
            className="list-unstyled ps-0"
            onClickCapture={handleRouteClick}
        >
            <li className={`mb-1 ${getActiveNav("/admin")}`}>
                <a
                    href="/admin"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Dashboard
                </a>
            </li>
            <li className={`mb-1 ${getActiveNav("/order")}`}>
                <Link
                    to="/admin/order"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Orders{" "}
                    {notification?.order > 0 && (
                        <span className="mx-2 bg-danger px-2 rounded-circle">
                            {notification?.order}
                        </span>
                    )}
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/product")}`}>
                <Link
                    to="/admin/product"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Products
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/category")}`}>
                <Link
                    to="/admin/category"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Category
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/page")}`}>
                <Link
                    to="/admin/page"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Page
                </Link>
            </li>
            <li className="border-top my-3"></li>
            <li className={`mb-1 ${getActiveNav("/banner")}`}>
                <Link
                    to="/admin/banner"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Banner
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/blog")}`}>
                <Link
                    to="/admin/blog"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Blog
                </Link>
            </li>

            <li className={`mb-1 ${getActiveNav("/coupon")}`}>
                <Link
                    to="/admin/coupon"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Coupon
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/contact")}`}>
                <Link
                    to="/admin/contact"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Contact
                    {notification?.contact > 0 && (
                        <span className="mx-2 bg-danger px-2 rounded-circle">
                            {notification?.contact}
                        </span>
                    )}
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/config")}`}>
                <Link
                    to="/admin/config"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Config
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/enquiry")}`}>
                <Link
                    to="/admin/enquiry"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Enquiry{" "}
                    {notification?.enquiry > 0 && (
                        <span className="mx-2 bg-danger px-2 rounded-circle">
                            {notification?.enquiry}
                        </span>
                    )}
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/users")}`}>
                <Link
                    to="/admin/users"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Users
                </Link>
            </li>

            <li className={`mb-1 ${getActiveNav("/review")}`}>
                <Link
                    to="/admin/review"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Reviews
                </Link>
            </li>

            <li className={`mb-1 ${getActiveNav("/comment")}`}>
                <Link
                    to="/admin/comment"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Comment
                </Link>
            </li>
            <li className={`mb-1 ${getActiveNav("/size")}`}>
                <Link
                    to="/admin/size"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Size
                </Link>
            </li>
            <li className="border-top my-3"></li>
            <li className={`mb-1 ${getActiveNav("/changePassword")}`}>
                <a
                    href="/admin/users/changePassword"
                    className="link-dark d-inline-flex text-decoration-none rounded ms-3 p-2"
                >
                    Change Password
                </a>
            </li>
        </ul>
    );
};

import Input from "components/Input";
import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { createSize, deleteSize, editSize, getSizes } from "api/request.api";
import { Link } from "react-router-dom";
import validateField from "helpers/validateData";
import { toast } from "react-toastify";
import sizeSchema from "schema/schema.size";

function Index() {
    const [sizes, setSizes] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [errors, setErrors] = useState({});

    const fetchSizes = async () => {
        const { data } = await getSizes();
        setSizes(data);
    };

    useEffect(() => {
        fetchSizes();
    }, []);

    const [data, setData] = useState({
        min: "",
        max: "",
    });
    const [selectedSizeId, setSelectedSizeId] = useState(null);

    const handleInputChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setData({
            ...data,
            [name]: value,
        });
    };

    const resetData = () => {
        setIsEdit(false);
        setData({
            min: "",
            max: "",
        });
    };

    const handleSaveSize = async () => {
        const fieldErrors = validateField(sizeSchema, data);
        setErrors(fieldErrors);

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }

        const result = isEdit
            ? await editSize(selectedSizeId, data)
            : await createSize(data);

        if (result.status !== 500) {
            resetData();
            fetchSizes();
        }
    };

    const handleEditClick = (data) => {
        setSelectedSizeId(data._id);
        setIsEdit(true);
        setData({
            min: data.min,
            max: data.max,
        });
    };

    const handleOnDelete = async (size) => {
        const data = await deleteSize(size._id);

        if (data?.success) {
            resetData();
            fetchSizes();
        }else{
            toast.error(data?.data?.message)
        }
    };

    const handleDeleteClick = (size) => {
        Swal.fire({
            title: `Do you want to delete size \`${size.min} -  ${size.max}\`?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleOnDelete(size);
            }
        });
    };

    return (
        <div className="p-4">
            <section>
                <div className="row">
                    <div className="col-sm-6 mb-3">
                        <h4 className="fw-semibold">Add Size</h4>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link href="/admin">Home</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="#">Size</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="p-4 bg-white rounded-3">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold my-0">New Size</h6>
                        <p>Fill all information below</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Input
                            id="min"
                            name="min"
                            placeholder="Enter start size"
                            label="Start size:"
                            onChange={handleInputChange}
                            value={data.min}
                            type="text"
                            error={errors.min}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Input
                            id="max"
                            name="max"
                            placeholder="Enter end size"
                            label="End size:"
                            onChange={handleInputChange}
                            value={data.max}
                            type="text"
                            error={errors.max}
                        />
                    </div>
                </div>

                <button
                    className="btn btn-primary rounded-1 mb-2"
                    onClick={handleSaveSize}
                >
                    Save Changes
                </button>
                {isEdit && (
                    <button
                        className="btn btn-danger rounded-1 mb-2 ms-3"
                        onClick={resetData}
                    >
                        Cancel
                        <i className="bi bi-pencil-square ps-2"></i>
                    </button>
                )}

                <h5 className="fw-semibold mt-3">All Sizes</h5>
                <ol className="list-group list-group-flush list-group-numbered">
                    {sizes.map((size) => (
                        <li className="list-group-item d-flex justify-content-between align-items-start" key={size?._id}>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    {size.min} to {size.max}
                                    <i
                                        className="bi bi-pencil-square mx-2 text-primary"
                                        onClick={() => handleEditClick(size)}
                                    ></i>
                                    <i
                                        className="bi bi-archive-fill text-danger"
                                        onClick={() => handleDeleteClick(size)}
                                    ></i>
                                </div>
                            </div>
                        </li>
                    ))}
                </ol>
            </section>
        </div>
    );
}

export default Index;

import React, { useState } from "react";

function CategoryTree({ category, onEdit, onDelete, parentId, number }) {
    const [status, setStatus] = useState(false);

    const getColor = () => {
        return status ? "text-secondary" : "text-primary";
    };

    return (
        <>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">
                        {`${number} ${category.name}`}{" "}
                        {category.highlight ? (
                            <i className="bi bi-circle-fill text-success"></i>
                        ) : (
                            ""
                        )}
                        <i
                            className="bi bi-pencil-square mx-2 text-primary"
                            onClick={() => onEdit(category, parentId)}
                        ></i>
                        <i
                            className="bi bi-archive-fill text-danger"
                            onClick={() => onDelete(category)}
                        ></i>
                    </div>
                </div>

                {category.children.length > 0 && (
                    <span
                        className={`${getColor()}`}
                        onClick={() => setStatus(!status)}
                    >
                        {status ? (
                            <i className="bi bi-chevron-compact-up"></i>
                        ) : (
                            <i className="bi bi-chevron-compact-down"></i>
                        )}
                    </span>
                )}
            </li>
            {status && (
                <ol className="list-group list-group-flush ps-3">
                    {category.children.length > 0 &&
                        category.children.map((el, index) => (
                            <CategoryTree
                                category={el}
                                key={index}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                parentId={category._id}
                                number={`${number}.${index + 1}`}
                            />
                        ))}
                </ol>
            )}
        </>
    );
}

export default CategoryTree;

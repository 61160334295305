import Joi from "joi";

const productSchema = Joi.object({
    name: Joi.string().min(5).required(),
    summary: Joi.string().min(3).required(),
    description: Joi.string().min(3).required(),
    delivery: Joi.string().min(3).required(),
    returnPolicy: Joi.string().min(3).required(),
    category: Joi.string().required(),
    isFeatured: Joi.bool(),
    thumbnail: Joi.required(),
}).options({ abortEarly: false });

export default productSchema;

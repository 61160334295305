import Table from "components/Table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import {
    deliverOrder,
    shipOrder,
    getOrders,
    markOrderAsRead,
    markOrderAsUnread,
} from "api/request.api";
import { Button } from "components";
import Swal from "sweetalert2";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import NotificationContext from "context/NotificationContext";
import OrderInvoice from "components/OrderInvoice";
import { Link, useLocation } from "react-router-dom";

function OrderIndex() {
    const columns = useMemo(() => [
        {
            Header: "Products",
            accessor: "products",
            Cell: ({ cell }) => renderProducts(cell.value),
        },
        {
            Header: "Order Detail",
            accessor: "_id",
            Cell: ({ row }) => renderOrderDetail(row.original),
        },
        {
            Header: "Shipping Status",
            accessor: "shippingStatus",
            Cell: ({ cell, row }) => (
                <div className="dropdown">
                    <button className={`btn btn-sm btn-${cell.value === "pending" ? "secondary" : (cell.value === "shipped" ? "warning" : "success")} dropdown-toggle`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {row.original?.shippingStatus}
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" href="#" onClick={() => onClickShippingStatus(row.original._id, "shipped")}>Shipped</Link></li>
                        <li><Link className="dropdown-item" href="#" onClick={() => onClickShippingStatus(row.original._id, "delivered")}>Delivered</Link></li>
                    </ul>
                </div>
            ),
        },
        {
            Header: "Mark as Read",
            accessor: "isRead",
            Cell: ({ cell, row }) => (
                <Button
                    type="button"
                    variant={cell.value ? "secondary" : "primary"}
                    icon={
                        cell.value
                            ? "bi bi-envelope-open-fill"
                            : "bi bi-envelope-fill"
                    }
                    title={cell.value ? "Mark as unread" : "Mark as read"}
                    onClick={() =>
                        cell.value
                            ? markAsUnread(row.original._id)
                            : markAsRead(row.original._id)
                    }
                />
            ),
        },
        {
            Header: "Actions",
            accessor: "shippingType",
            Cell: ({ cell, row }) => (
                <>
                    <Button
                        type="button"
                        variant="primary"
                        className="mb-2"
                        icon="bi bi-eye"
                        onClick={() => handleViewReview(row.original)}
                    />
                </>
            ),
        },
    ]);

    const [orders, setOrders] = useState([]);
    const { notification, setNotification } = useContext(NotificationContext);
    const location = useLocation();
    const isOrderPage = location.pathname.includes("/order");

    const getDate = (date) => {
        const desiredFormat = "YYYY-MM-DD"; // Desired format
        return moment(date).format(desiredFormat);
    };

    const updateOrders = (id, state) => {
        const index = orders.findIndex((item) => item._id === id);
        const orderData = [...orders];
        orderData[index].isRead = state;

        setOrders(orderData);
    };

    const markAsRead = async (id) => {
        await markOrderAsRead(id);
        setNotification({
            ...notification,
            order: notification.order - 1,
        });
        updateOrders(id, true);
    };

    const markAsUnread = async (id) => {
        await markOrderAsUnread(id);
        setNotification({
            ...notification,
            order: notification.order + 1,
        });
        updateOrders(id, false);
    };

    const handleViewReview = (order) => {
        const message = <OrderInvoice order={order} getDate={getDate} />;

        markAsRead(order._id);

        Swal.fire({
            // title: "Ordered Products",
            html: ReactDOMServer.renderToString(message),
            showCloseButton: true,
            width: "820px",
        });
    };

    async function fetchOrders() {
        const { data } = await getOrders();
        const orders = isOrderPage ? data : data.slice(0, 5);
        setOrders(orders);
    }

    useEffect(() => {
        fetchOrders();
    }, []);

    const handleDeliverOrder = async (orderId, status) => {
        let data;
        if (status === "shipped")
            data = await shipOrder(orderId);
        else if (status === "delivered")
            data = await deliverOrder(orderId);

        if (data?.success) {
            fetchOrders();
        }
    };

    const onClickShippingStatus = (id, status) => {
        Swal.fire({
            title: `Do you want to change its status to ${status}?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeliverOrder(id, status);
            }
        });
    };

    const renderProducts = (products) => {
        return (
            <ul className="list-group list-group-flush">
                {products.map((product) => (
                    <li className="list-group-item bg-transparent" key={product?._id}>
                        <div className="d-flex">
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/${product.thumbnail}`}
                                alt=""
                                className="rounded me-2"
                                width={60}
                                style={{ objectFit: "cover" }}
                            />
                            <div>
                                <span>{product.name}</span>
                                <br />
                                <span>
                                    Size:{" "}
                                    {`${product.size.depth} * ${product.size.width} * ${product.size.height}`}
                                </span>
                                <br />
                                <span>Qty: {product.quantity}</span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const renderOrderDetail = (order) => {
        return (
            <table className="table table-borderless" style={{ width: "270px" }}>
                <tbody>
                    <tr>
                        <td>Ordered at:</td>
                        <td>{getDate(order.createdAt)}</td>
                    </tr>
                    <tr>
                        <td>OrderId:</td>
                        <td>{order._id}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{order.shippingStatus}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total:</th>
                        <td>${order.grandTotal}</td>
                    </tr>
                </tfoot>
            </table>
            // <div className="fw-normal">
            //     <span>Ordered at: {getDate(order.createdAt)}</span>
            //     <br />
            //     <span>Order id: {order._id}</span>
            //     <br />
            //     <span>Status: {order.shippingStatus}</span> <br />
            //     <span>Total: ${order.grandTotal}</span>
            // </div>
        );
    };

    return (
        <div className="p-2">
            <PageHead title="Orders List" />
            <PageContent>
                <div className={`${!isOrderPage && "latest-order-section"}`}>
                    <Table
                        columns={columns}
                        data={orders}
                        filter={isOrderPage}
                    />
                </div>
                {!isOrderPage && (
                    <Link
                        to="/admin/order"
                        className="card-link text-center text-primary d-block"
                    >
                        Show More
                    </Link>
                )}
            </PageContent>
        </div>
    );
}

export default OrderIndex;

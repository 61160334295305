import React from "react";
import { useFilters, useSortBy, useTable, usePagination } from "react-table";
import _ from "lodash";
function Table({ columns, data, hstrip, vstrip, filter }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        setFilter,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 15 },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const handleFilterChange = (columnId, event) => {
        const value = event.target.value || undefined;
        setFilter(columnId, value);
    };

    const rangeValue = Math.ceil(data.length / pageSize);
    const pageNumbers = _.range(1, rangeValue + 1);

    // Render the UI for your table
    return (
        <div className="table-responsive">
            <table
                className={`table table-hover align-middle ${
                    hstrip ? "table-striped" : ""
                } ${vstrip ? "table-striped-columns" : ""}`}
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th>S.N</th>
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    scope="col"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <i className="bi bi-arrow-up fw-bold ms-2"></i>
                                            ) : (
                                                <i className="bi bi-arrow-down fw-bold ms-2"></i>
                                            )
                                        ) : (
                                            <i className="bi bi-arrow-down-up fw-bold ms-2"></i>
                                        )}
                                    </span>
                                    <div>
                                        {column.canFilter &&
                                        filter &&
                                        headerGroup.headers.length !== i + 1 ? (
                                            <input
                                                value={column.filterValue || ""}
                                                onChange={(event) =>
                                                    handleFilterChange(
                                                        column.id,
                                                        event
                                                    )
                                                }
                                                className="form-control"
                                                placeholder="Filter..."
                                            />
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                <td>{i + 1}</td>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {pageNumbers.length > 1 && (
                <div className="d-flex justify-content-center">
                    <nav className="me-2">
                        <ul className="pagination">
                            <li
                                className={`page-item ${
                                    !canPreviousPage ? "disabled" : ""
                                }`}
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <span className="page-link">Previous</span>
                            </li>
                            {pageNumbers.map((index) => (
                                <li
                                    className={`page-item ${
                                        pageIndex === index - 1 ? "active" : ""
                                    }`}
                                    onClick={() => gotoPage(index - 1)}
                                    disabled={!canNextPage}
                                    aria-current="page"
                                >
                                    <span className="page-link">{index}</span>
                                </li>
                            ))}

                            <li
                                className={`page-item ${
                                    !canNextPage ? "disabled" : ""
                                }`}
                                onClick={() => nextPage()}
                            >
                                <a className="page-link" href="#">
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <span>
                        Showing{" "}
                        <strong className="mx-2 mt-2 d-inline-block">
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>

                        <div>
                            <select
                                value={pageSize}
                                className="form-select ms-2"
                                onChange={(e) => {
                                    setPageSize(e.target.value);
                                }}
                            >
                                {[5, 10, 15, 20, 30].map((size) => (
                                    <option key={size} value={size}>
                                        Show {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                </div>
            )}
        </div>
    );
}

export default Table;

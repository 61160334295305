import {
    createProduct,
    deleteVariant,
    editProduct,
    getCategories,
    getProduct,
    getSizes,
} from "api/request.api";
import Editor from "components/Editor";
import Input from "components/Input";
import Select from "components/Select";
import FileUploader from "components/FileUploader";
import React, { useEffect, useRef, useState } from "react";
import Variant from "./Variant";
import { toast } from "react-toastify";
import Joi from "joi";
import validateField from "helpers/validateData";
import createForm from "helpers/createForm";
import { Link, useLocation, useParams } from "react-router-dom";
import productSchema from "schema/schema.product";

function Create() {
    const [allCategories, setAllCategories] = useState([]);
    const [thumbnail, setThumbnail] = useState();
    const [thumbnailPreview, setThumbnailPreview] = useState();
    const [productId, setProductId] = useState(null);
    const [variantData, setVariantData] = useState([]);
    const [productReadOnly, setProductReadOnly] = useState(false);
    const [variantsReadOnly, setVariantsReadOnly] = useState([]);

    const variantRefs = useRef([]);
    const basicInfoRef = useRef(null);
    const [sizes, setSizes] = useState([]);
    const [errors, setErrors] = useState({});
    const [variantCount, setVariantCount] = useState([]);
    const [initialDescription, setInitialDescription] = useState("");

    const [isSaving, setIsSaving] = useState(false);
    const params = useParams();
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        const id = params.id;
        if (id) {
            fetchProduct(id);
        }
    }, []);

    const fetchAllCategories = async () => {
        const { data } = await getCategories();
        let categories = [];

        data.forEach((cat) => {
            categories.push({
                value: cat._id,
                label: cat.name,
            });
            cat.children.forEach((child) => {
                categories.push({
                    value: child._id,
                    label: `${cat.name} > ${child.name}`,
                });
            });
        });
        setAllCategories(categories);
    };

    const fetchSizes = async () => {
        const { data } = await getSizes();
        setSizes(
            data.map((el) => {
                return { value: el._id, label: `${el.min} to ${el.max}` };
            })
        );
    };

    const fetchProduct = async (id) => {
        const { data } = await getProduct(id);

        basicInfoRef.current.name.value = data.name;
        basicInfoRef.current.delivery.value = data.delivery;
        basicInfoRef.current.returnPolicy.value = data.returnPolicy;
        basicInfoRef.current.summary.value = data.summary;

        basicInfoRef.current.thumbnail = data.thumbnail;
        basicInfoRef.current.isFeatured.checked = data.isFeatured;

        setInitialDescription(data.description);

        setParentCategory({
            value: data.category._id,
            label: data.category.name,
        });

        setProductId(data._id);

        setVariantCount(data?.variant?.map((_, index) => index + 1) ?? []);

        variantRefs.current = data?.variant?.map(
            (_, index) => variantRefs.current[index] || React.createRef()
        );

        setVariantsReadOnly(data?.variant?.map((_) => true));

        setVariantData(data?.variant);
    };

    const [parentCategory, setParentCategory] = useState("");
    const editorRef = useRef(null);

    const handleThumbnailChange = ({ currentTarget }) => {
        setThumbnail(currentTarget.files[0]);
    };

    useEffect(() => {
        fetchAllCategories();
        fetchSizes();
    }, []);

    useEffect(() => {
        if (!thumbnail) {
            setThumbnailPreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(thumbnail);
        setThumbnailPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [thumbnail]);

    const handleCategoryChange = (element) => {
        setParentCategory(element);
    };

    const handleOnVariantDiscard = (index) => {
        if (variantCount.length !== 1) {
            let counts = variantCount.filter((_, i) => i !== index);

            setVariantCount([...counts]);

            variantRefs.current = variantRefs.current.filter(
                (_, i) => i !== index
            );
            setVariantsReadOnly(
                variantsReadOnly.filter((_, key) => key !== index)
            );
        }
    };

    const onDeleteVariant = async (productId, variantId, index) => {
        if (variantCount.length !== 1) {
            await deleteVariant(productId, variantId);
            if (variantData.length > 0) {
                setVariantData(variantData.filter((_, i) => i !== index));
            }
            handleOnVariantDiscard(index);
        } else {
            toast.error("Only 1 variant present. Can't be removed!");
        }
    };

    const handleOnVariantAddMore = () => {
        let counts;
        if (variantCount.length === 0) {
            counts = [...variantCount, 1];
        } else {
            counts = [
                ...variantCount,
                variantCount[variantCount.length - 1] + 1,
            ];
        }
        setVariantCount(counts);
        setVariantsReadOnly([...variantsReadOnly, false]);

        variantRefs.current = counts.map(
            (_, index) => variantRefs.current[index] || React.createRef()
        );
    };

    const handleCreateProduct = async () => {
        setIsSaving(true);

        let data = {
            name: basicInfoRef.current.name.value,
            delivery: basicInfoRef.current.delivery.value,
            returnPolicy: basicInfoRef.current.returnPolicy.value,
            summary: basicInfoRef.current.summary.value,
            description: editorRef.current.getContent(),
            thumbnail: thumbnail,
            category: parentCategory.value,
            isFeatured: basicInfoRef.current.isFeatured.checked,
        };

        const fieldErrors = validateField(
            productSchema,
            data,
            productId ? ["thumbnail"] : []
        );
        setErrors(fieldErrors);

        if (Object.keys(fieldErrors).length > 0) {
            setIsSaving(false);
            return null;
        }

        const response = productId
            ? await editProduct(productId, createForm(data))
            : await createProduct(createForm(data));

        if (response.success) {
            !productId && handleOnVariantAddMore();
            setProductId(response.data._id);
            setProductReadOnly(true);
            setThumbnail(null);
            basicInfoRef.current.thumbnail = response.data.thumbnail;
            setIsSaving(false);
        }
    };

    const toggleVariantReadOnly = async (index) => {
        const data = [...variantsReadOnly];
        data[index] = !data[index];
        setVariantsReadOnly([...data]);
    };

    return (
        <div className="p-4">
            <section>
                <div className="row">
                    <div className="col-sm-6 mb-3">
                        <h4 className="fw-semibold">
                            {path.includes("create") ? "Add" : "Edit"} Product
                        </h4>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin">Home</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/admin/product">Products</Link>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                >
                                    {path.includes("create")
                                        ? "Create"
                                        : "Edit"}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="p-4 mb-3 bg-white rounded-3">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold my-0">
                            Basic Product Information
                        </h6>
                        <p>Fill all information below</p>
                    </div>
                </div>
                <form ref={basicInfoRef}>
                    <div className="row">
                        <div className="col-sm-6">
                            <Input
                                id="name"
                                name="name"
                                placeholder="Product name"
                                label="Product name:"
                                type="text"
                                error={errors.name}
                                readOnly={productReadOnly}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Select
                                id="parentCategory"
                                label="Parent Category:"
                                name="parentCategory"
                                onChange={handleCategoryChange}
                                data={allCategories}
                                readOnly={productReadOnly}
                                default={parentCategory}
                            />
                            {errors.parentCategory && (
                                <span className="d-inline-block text-danger mt-2">
                                    {errors.parentCategory}
                                </span>
                            )}
                        </div>
                        <div className="col-sm-4">
                            <Input
                                id="delivery"
                                name="delivery"
                                placeholder="Delivery"
                                label="Delivery:"
                                type="type"
                                error={errors.delivery}
                                readOnly={productReadOnly}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Input
                                id="returnPolicy"
                                name="returnPolicy"
                                placeholder="Return Policy"
                                readOnly={productReadOnly}
                                label="Return Policy:"
                                error={errors.returnPolicy}
                            />
                        </div>
                        <div className="col-sm-4">
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name="isFeatured"
                                    id="flexSwitchCheckDefault"
                                    readOnly={productReadOnly}
                                />
                                <label
                                    className="form-check-label fw-semibold"
                                    htmlFor="flexSwitchCheckDefault"
                                >
                                    Is Featured
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <FileUploader
                                id="thumbnail1"
                                value={thumbnail}
                                label="Thumbnail:"
                                disabled={productReadOnly}
                                multiple={false}
                                error={errors.thumbnail}
                                onChange={handleThumbnailChange}
                            />
                        </div>
                        <div className="col-sm-6 d-flex">
                            {thumbnailPreview && (
                                <div className="me-3">
                                    <span className="fw-semibold">
                                        Preview Thumbnail
                                    </span>
                                    <br />
                                    <img
                                        src={thumbnailPreview}
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            )}
                            {basicInfoRef.current?.thumbnail && (
                                <div>
                                    <span className="fw-semibold">
                                        Existing Thumbnail
                                    </span>
                                    <br />
                                    <img
                                        src={`${process.env.REACT_APP_BASE_URL}/${basicInfoRef.current?.thumbnail}`}
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-sm-3 mb-3">
                            <label
                                htmlFor="summary"
                                className="form-label fw-semibold"
                            >
                                Summary:
                            </label>
                            <textarea
                                className="form-control"
                                id="summary"
                                rows="13"
                                error={errors.summary}
                                name="summary"
                                readOnly={productReadOnly}
                            ></textarea>
                            {errors.summary && (
                                <span className="d-inline-block text-danger mt-2">
                                    {errors.summary}
                                </span>
                            )}
                        </div>

                        <div className="col-sm-9 mb-3">
                            <Editor
                                id="description"
                                label="Description:"
                                editorRef={editorRef}
                                initialValue={initialDescription}
                                readOnly={productReadOnly}
                            />
                            {errors.description && (
                                <span className="d-inline-block text-danger mt-2">
                                    {errors.description}
                                </span>
                            )}
                        </div>
                    </div>
                </form>

                {productReadOnly ? (
                    <button
                        className="btn btn-primary rounded-1"
                        onClick={() => setProductReadOnly(false)}
                    >
                        <i className="bi bi-pencil-square pe-1"></i>
                        Edit
                    </button>
                ) : (
                    <button
                        className={`btn btn-primary rounded-1`}
                        disabled={isSaving}
                        onClick={handleCreateProduct}
                    >
                        {isSaving && (
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                        {productId ? "Update" : "Save"}
                    </button>
                )}

                {productId && variantCount?.length === 0 && (
                    <button
                        className="btn btn-primary rounded-1 ms-2"
                        onClick={handleOnVariantAddMore}
                    >
                        <i className="bi bi-plus"></i>Add more
                    </button>
                )}
            </section>
            {productId && (
                <div
                    className="accordion accordion-flush"
                    id="variantAccordian"
                >
                    <div className="row">
                        {variantRefs.current.map((ref, index) => (
                            <div
                                className="col-sm-12 mb-3"
                                key={variantCount[index]}
                            >
                                <Variant
                                    index={index}
                                    variantData={variantData[index] || null}
                                    variantRef={ref}
                                    onAddMore={handleOnVariantAddMore}
                                    onDiscard={handleOnVariantDiscard}
                                    onDelete={onDeleteVariant}
                                    readOnly={variantsReadOnly[index]}
                                    productId={productId}
                                    toggle={toggleVariantReadOnly}
                                    sizes={sizes}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Create;

import React from 'react'

function Switch(props) {
    return (
        <div className="mb-3">
            <label
                className="form-check-label fw-semibold"
                htmlFor={props.id}
            >
                {props.label}
            </label>
            <br />
            <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name={props.name}
                id={props.id}
                checked={props.checked}
                onChange={props.onChange}
            />
        </div>
    )
}

export default Switch
import React, { useEffect, useRef } from "react";

function FileUploader(props) {
    const fileRef = useRef();

    useEffect(() => {
        if(!props.value){
            fileRef.current.value = null
        }
    }, [props.value])
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label">
                {props.label}
            </label>
            <input
                className="form-control"
                type="file"
                name={props.name}
                ref={fileRef}
                id={props.id}
                onChange={props.onChange}
                accept={props.acceptVideo ? 'video/*,image/*' : 'image/*'}
                multiple={props.multiple}
                disabled={props.disabled || false}
            />
            {props.error && (
                <span className="text-danger d-inline-block pt-2">{props.error}</span>
            )}
        </div>
    );
}

export default FileUploader;

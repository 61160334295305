import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function PageHead(props) {
    const location = useLocation()
    const path = location.pathname.split('/')
    return (
        <section>
            <div className="row">
                <div className="col-sm-6 mb-3">
                    <h4 className="fw-semibold">{props.title}</h4>
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                    {path.length > 2 && <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {
                                path.map((item, index) => {
                                    if (index === 0 || index > 3) return null
                                    return (
                                        <li className="breadcrumb-item" key={index}>
                                            <Link to={index < 3 ? path.slice(0, index + 1).join('/') : "#"}>{item}</Link>
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </nav>}
                </div>
            </div>
        </section>
    )
}

export default PageHead
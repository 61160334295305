import React from "react";
import ReactSelect from "react-select";

function Select(props) {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label fw-semibold">
                {props.label}
            </label>
            <ReactSelect
                value={props.default}
                onChange={props.onChange}
                name={props.name}
                options={props.data}
                isDisabled={props.readOnly || false}
                maxMenuHeight={120}
                className="custom-react-select"
            />
        </div>
    );
}

export default Select;

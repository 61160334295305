import { Doughnut } from "react-chartjs-2";

function SalesDoughnot({orderData}) {
    const data = {
        labels: Object.keys(orderData),
        datasets: [
            {
                data: Object.values(orderData),
                backgroundColor: ["orange", "purple"],
                // hoverBackgroundColor: ["lightgreen", "lightred"],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };
    return (
        <div className="card p-2">
            <div className="d-flex justify-content-between">
                <h4 className="fw-semibold">Order status</h4>
                <h5 className="fw-normal me-2">This month</h5>
            </div>
            <div className="card-body">
                <Doughnut data={data} options={options} />
            </div>
        </div>
    );
}

export default SalesDoughnot;

import Input from "components/Input";
import React, { useEffect, useRef, useState } from "react";

import FileUploader from "components/FileUploader";
import Editor from "components/Editor";
import { editBlog, editPage, getBlog, getPage } from "api/request.api";
import { useNavigate, useParams } from "react-router-dom";
import createForm from "helpers/createForm";
import { Button, PageContent, PageHead, Switch } from "components";
import validateField from "helpers/validateData";
import { toast } from "react-toastify";
import pageSchema from "schema/schema.page";

const Joi = require("joi");
function PageEdit() {
    const [errors, setErrors] = useState({});
    const [pageId, setPageId] = useState("");
    const [content, setContent] = useState("");
    const pageRef = useRef();
    const editorRef = useRef(null);

    const navigate = useNavigate();

    const slug = useParams().id;

    const fetchPage = async () => {
        const { data } = await getPage(slug);
        pageRef.current.title.value = data.title;
        pageRef.current.highlight.checked = data.highlight;
        pageRef.current.slug.value = data.slug;
        pageRef.current.order.value = data.order
        
        setPageId(data._id)
        setContent(data.content);
    };

    useEffect(() => {
        fetchPage();
    }, []);

    const validateField = (data) => {
        const { error } = pageSchema.validate(data);
        const tempErrors = {};
        if (error) {
            error.details.forEach((el) => {
                tempErrors[el.path[0]] = el.message;
            });
        }
        return tempErrors;
    };

    const handleOnEdit = async (e) => {
        e.preventDefault()
        const page = {
            content: editorRef.current.getContent(),
            title: pageRef.current.title.value.trim(),
            order: pageRef.current.order.value,
            slug: pageRef.current.slug.value.trim().toLowerCase(),
            highlight: pageRef.current.highlight.checked
        };

        const fieldErrors = validateField(page);

        setErrors(fieldErrors);
        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }

        const result = await editPage(pageId,page);
        if (result.success) {
            navigate("/admin/page");
        } else {
            toast.error(result?.data?.message);
        }
    };

    return (
        <div className="p-4">
            <PageHead title="Edit Page" />

            <PageContent title subtitle>
            <form onSubmit={handleOnEdit} ref={pageRef}>
                    <div className="row">
                        <div className="col-sm-6">
                            <Input
                                id="title"
                                name="title"
                                placeholder="Enter the title"
                                label="Title:"
                                type="text"
                                error={errors.title}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="order"
                                name="order"
                                placeholder="Enter the order"
                                label="Order:"
                                type="number"
                                error={errors.order}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="slug"
                                name="slug"
                                placeholder="Enter the slug"
                                label="slug:"
                                type="text"
                                error={errors.slug}
                            />
                        </div>
                        <div className="col-sm-6">
                        <Switch
                            id="highlight"
                            name="highlight"
                            label="Show in Navbar:"
                        />
                    </div>
                    
                        <div className="col-sm-12 mb-3">
                            <Editor
                                id="description"
                                name="content"
                                label="Description:"
                                editorRef={editorRef}
                                initialValue={content}
                            />

                            {errors.content && (
                                <span className="d-inline-block text-danger mt-2">
                                    {errors.content}
                                </span>
                            )}
                        </div>
                    </div>
                    <button
                        className="btn btn-primary rounded-1"
                        type="submit"
                    >
                        Update Changes
                    </button>
               </form>
            </PageContent>
        </div>
    );
}

export default PageEdit;

import BlogIndex from "pages/blog/Index";
import BlogCreate from "pages/blog/Create";
import BlogEdit from "pages/blog/Edit";

import ProductIndex from "pages/product/Index";
import ProductCreate from "pages/product/Create";
import ProductEdit from "pages/product/Edit";

import CouponIndex from "pages/coupon/Index";
import CouponCreate from "pages/coupon/Create";
import CouponEdit from "pages/coupon/Edit";

import BannerIndex from "pages/banner/Index";
import BannerCreate from "pages/banner/Create";
import BannerEdit from "pages/banner/Edit";

import CategoryIndex from "pages/category/Index";

import ConfigIndex from "pages/config/Index";
import ConfigEdit from "pages/config/Edit";

import UserIndex from "pages/user/Index";
import Dashboard from "pages/dashboard/Dashboard";

import SizeIndex from "pages/size/Index";
import ContactIndex from "pages/contact/Index";
import ReviewIndex from "pages/reviews/Index";
import OrderIndex from "pages/orders/Index";

import EnquiryIndex from "pages/enquiry/Index";
import ChangePassword from "pages/user/ChangePassword";
import PageIndex from "pages/page/Index";
import PageCreate from "pages/page/Create";
import PageEdit from "pages/page/Edit";
import CommentIndex from "pages/comments/Index";

const adminRoute = [
    {
        path: "/admin",
        element: <Dashboard />,
    },
    {
        path: "category",
        children: [
            {
                path: "",
                element: <CategoryIndex />,
            },
        ],
    },
    {
        path: "product",
        children: [
            {
                path: "",
                element: <ProductIndex />,
            },
            {
                path: "create",
                element: <ProductCreate />,
            },
            {
                path: "edit/:id",
                element: <ProductCreate />,
            },
        ],
    },
    {
        path: "blog",
        children: [
            {
                path: "",
                element: <BlogIndex />,
            },
            {
                path: "create",
                element: <BlogCreate />,
            },
            {
                path: "edit/:id",
                element: <BlogEdit />,
            },
        ],
    },
    {
        path: "coupon",
        children: [
            {
                path: "",
                element: <CouponIndex />,
            },
            {
                path: "create",
                element: <CouponCreate />,
            },
            {
                path: "edit/:id",
                element: <CouponEdit />,
            },
        ],
    },
    {
        path: "banner",
        children: [
            {
                path: "",
                element: <BannerIndex />,
            },
            {
                path: "create",
                element: <BannerCreate />,
            },
            {
                path: "edit/:id",
                element: <BannerEdit />,
            },
        ],
    },
    {
        path: "config",
        children: [
            {
                path: "",
                element: <ConfigIndex />,
            },
            {
                path: "edit/:id",
                element: <ConfigEdit />,
            },
        ],
    },
    {
        path: "users",
        children: [
            {
                path: "",
                element: <UserIndex />,
            },
            {
                path: "changePassword",
                element: <ChangePassword />,
            },
        ],
    },
    {
        path: "size",
        element: <SizeIndex />,
    },
    {
        path: "contact",
        element: <ContactIndex />,
    },
    {
        path: "enquiry",
        element: <EnquiryIndex />,
    },
    {
        path: "review",
        element: <ReviewIndex />,
    },
    {
        path: "order",
        element: <OrderIndex />,
    },
    {
        path: "comment",
        element: <CommentIndex />,
    },
    {
        path: "page",
        children: [
            {
                path: "",
                element: <PageIndex />,
            },
            {
                path: "create",
                element: <PageCreate />,
            },
            {
                path: "edit/:id",
                element: <PageEdit />,
            },
        ],
    },
];

export default adminRoute;

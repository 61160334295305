import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deleteBanner, deleteBlog, getBanners } from "api/request.api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";

function Index() {
    const columns = useMemo(() => [
        {
            Header: "Image",
            accessor: "image",
            Cell: ({ cell }) => (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/${cell.value}`}
                    alt="User"
                    width="100"
                />
            ),
        },
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Detail",
            accessor: "detail",
        },
        {
            Header: "Status",
            accessor: "isActive",
            Cell: ({ cell }) => (cell.value ? "Active" : "Inactive"),
        },
        {
            Header: "Priority",
            accessor: "priority",
        },
        {
            Header: "Variant",
            accessor: "variant",
        },
        {
            Header: "Actions",
            accessor: "_id",
            Cell: ({ cell }) => (
                <>
                    <Button
                        type="button"
                        variant="info"
                        className="ms-2"
                        icon="bi bi-pencil-square"
                        onClick={() =>
                            navigate(`/admin/banner/edit/${cell.value}`)
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="ms-2"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />
                </>
            ),
        },
    ]);

    const [banners, setBanners] = useState([]);
    const navigate = useNavigate();

    async function getAndSetBanner() {
        const { data } = await getBanners();
        setBanners(data);
    }

    const handleDeleteBanner = async (id) => {
        const data = await deleteBanner(id);
        if (data.success) {
            getAndSetBanner();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this banner?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteBanner(id);
            }
        });
    };
    useEffect(() => {
        getAndSetBanner();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Banner List" />
            <PageContent>
                <div className="d-flex flex-row-reverse">
                    <Link
                        className="btn btn-primary"
                        role="button"
                        to="/admin/banner/create"
                    >
                        Create
                    </Link>
                </div>
                <Table columns={columns} data={banners} hstrip />
            </PageContent>
        </div>
    );
}

export default Index;

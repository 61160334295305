import React from "react";

function Input(props) {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label fw-semibold">
                {props.label}
            </label>
            <input
                type={props.type}
                className="form-control rounded-1"
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                readOnly={props.readOnly || false}
            />
            {props.error && (
                <span className="text-danger d-inline-block pt-2">{props.error}</span>
            )}
        </div>
    );
}

export default Input;

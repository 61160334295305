import Joi from "joi";

const commonMessage = {
    "string.empty": "{{#label}} is required",
    "string.min": "{{#label}} should have a minimum length of {#limit}",
    "any.required": "{{#label}} is required",
};

const changePasswordSchema = Joi.object({
    newPassword: Joi.string()
        .min(8)
        .required()
        .label("NewPassword")
        .messages(commonMessage),
    currentPassword: Joi.string().min(8).required().label("CurrentPassword"),

    reNewPassword: Joi.string()
        .min(8)
        .required()
        .label("reNewPassword")
        .messages(commonMessage),
});

export default changePasswordSchema;

import React, { useContext, useEffect, useState } from "react";
import FileUploader from "components/FileUploader";
import Input from "components/Input";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import {
    createVariant,
    deleteVariantImage,
    editVariant,
} from "api/request.api";
import { Select } from "components";
import validateField from "helpers/validateData";
import Joi from "joi";
import Swal from "sweetalert2";
import InputSelectGroup from "components/InputSelectGroup";
import UploadContext from "context/UploadContext";

import createVariantSchema from "schema/createVariantSchema";
import editVariantSchema from "schema/editVariantSchema";

function Variant({
    index,
    sizes,
    variantRef,
    toggle,
    onAddMore,
    onDelete,
    onDiscard,
    readOnly,
    variantData,
    productId,
}) {
    const [variantImages, setVariantImages] = useState([]);
    const [existingImages, setExistingImages] = useState([]);
    const [variantImagesPreview, setVariantImagesPreview] = useState([]);
    const [variantName, setVariantName] = useState("");
    const [variantId, setVariantId] = useState(null);
    const [errors, setErrors] = useState({});

    const [isSaving, setIsSaving] = useState(false);

    const [sizeType, setSizeType] = useState({
        value: null,
        label: "",
    });

    const videoTypes = ["mp4"];
    const { isUploading, setIsUploading } = useContext(UploadContext);

    const onSortEnd = (oldIndex, newIndex) => {
        setExistingImages((array) => arrayMove(array, oldIndex, newIndex));
    };

    const handleVariantImages = ({ currentTarget }) => {
        variantImagesPreview.map((el) => URL.revokeObjectURL(el));
        setVariantImages([...variantImages, ...currentTarget.files]);
    };

    const handleSizeChange = (element) => {
        setSizeType(element);
    };

    useEffect(() => {
        if (!variantImages) {
            setVariantImagesPreview([]);
            return;
        }

        const objectUrls = Object.keys(variantImages).map((el) => ({
            url: URL.createObjectURL(variantImages[el]),
            type: videoTypes.includes(variantImages[el]?.name?.split(".").pop())
                ? "video"
                : "image",
        }));

        setVariantImagesPreview(objectUrls);

        // free memory when ever this component is unmounted
        return () => objectUrls.map((el) => URL.revokeObjectURL(el));
    }, [variantImages]);

    useEffect(() => {
        if (variantData) {
            setVariantName(variantData?.name);
            variantRef.current.price.value = variantData?.price;
            variantRef.current.offerPrice.value = variantData?.offerPrice;
            variantRef.current.offerStart.value =
                variantData?.offerStart?.split("T")[0];
            variantRef.current.offerEnd.value =
                variantData?.offerEnd?.split("T")[0];
            variantRef.current.height.value =
                variantData?.size?.height?.split(" ")[0];
            variantRef.current.heightUnit.value = variantData?.size?.height
                ?.split(" ")
                .pop();
            variantRef.current.width.value =
                variantData?.size?.width?.split(" ")[0];
            variantRef.current.widthUnit.value = variantData?.size?.width
                ?.split(" ")
                .pop();
            variantRef.current.depth.value =
                variantData?.size?.depth?.split(" ")[0];
            variantRef.current.depthUnit.value = variantData?.size?.depth
                ?.split(" ")
                .pop();

            variantRef.current.stock.value = variantData?.stock;
            variantRef.current.order.value = variantData?.order;
            variantRef.current.sku.value = variantData?.sku;
            variantRef.current.weight.value =
                variantData?.weight?.split(" ")[0];
            variantRef.current.weightUnit.value = variantData?.weight
                ?.split(" ")
                ?.pop();

            setExistingImages(variantData?.images ?? []);

            const sizeIndex = sizes.findIndex(
                (item) => item.value === variantData?.sizeId?._id
            );

            setSizeType({
                value: variantData?.sizeId?._id,
                label: sizes[sizeIndex]?.label,
            });

            setVariantId(variantData?._id);
        }
    }, []);

    const handleCreateVariant = async () => {
        const variant = {
            name: variantRef.current.name.value,
            price: variantRef.current.price.value,
            offerPrice: variantRef.current.offerPrice.value,
            offerStart: variantRef.current.offerStart.value,
            offerEnd: variantRef.current.offerEnd.value,

            height: variantRef.current.height.value,
            width: variantRef.current.width.value,
            depth: variantRef.current.depth.value,

            stock: variantRef.current.stock.value,
            order: variantRef.current.order.value,
            sku: variantRef.current.sku.value,
            weight: variantRef.current.weight.value,

            sizeId: sizeType.value,
        };

        const fieldErrors = validateField(
            variantId ? editVariantSchema : createVariantSchema,
            variant,
            variant.offerPrice === "" ? ["offerPrice"] : []
        );

        if (
            (!variantId && variantImages.length === 0) ||
            (variantId &&
                existingImages.length == 0 &&
                variantImages.length == 0)
        ) {
            setErrors({
                ...fieldErrors,
                images: "Please upload variant images",
            });
            return null;
        } else setErrors(fieldErrors);

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }

        variant.weight += " " + variantRef.current.weightUnit.value;
        variant.height += " " + variantRef.current.heightUnit.value;
        variant.width += " " + variantRef.current.widthUnit.value;
        variant.depth += " " + variantRef.current.depthUnit.value;

        variant.size = JSON.stringify({
            height: variant.height,
            width: variant.width,
            depth: variant.depth,
        });

        delete variant.depth;
        delete variant.height;
        delete variant.width;

        const formData = new FormData();
        Object.keys(variant).forEach((key) =>
            formData.append(key, variant[key])
        );

        for (let keys of Object.keys(variantImages)) {
            formData.append("images", variantImages[keys]);
        }

        if (variantId) {
            for (let keys of Object.keys(existingImages)) {
                formData.append("existingImages", existingImages[keys]);
            }
        }

        try {
            setIsSaving(true);
            setIsUploading(true);
            const data = variantId
                ? await editVariant(variantId, formData)
                : await createVariant(productId, formData);

            if (data.success) {
                toggle(index);
                setVariantId(data.data._id);
                setVariantImages([]);
                setExistingImages(data.data?.images ?? []);
            } else {
                setErrors({ ...errors, sku: data?.data?.message });
            }
        } catch (err) {
            console.log("Error");
        }

        setIsSaving(false);
        setIsUploading(false);
    };

    const handleOnDelete = async (productId, variantId, index) => {
        Swal.fire({
            title: `Do you want to delete this variant?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                onDelete(productId, variantId, index);
            }
        });
    };
    const deleteExistingImage = async (imgPath) => {
        await deleteVariantImage(variantId, { imgPath });
        setExistingImages(existingImages.filter((el) => el !== imgPath));
    };

    const handleOnDeleteExistingImage = async (imgPath) => {
        Swal.fire({
            title: `Do you want to delete this image?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteExistingImage(imgPath);
            }
        });
    };

    const handleOnDeleteImage = async (index) => {
        variantImagesPreview.map((el) => URL.revokeObjectURL(el));
        setVariantImages(variantImages.filter((_, i) => index !== i));
    };
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordinan${index}`}
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                >
                    {variantName !== "" ? variantName : `Variant-${index}`}
                </button>
            </h2>
            <div
                id={`accordinan${index}`}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
            >
                <div className="accordion-body">
                    <section className="p-4 mt-3 bg-white rounded-3">
                        <form ref={variantRef}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Variant name"
                                        label="Variant name:"
                                        type="text"
                                        value={variantName}
                                        error={errors.name}
                                        onChange={(e) =>
                                            setVariantName(e.target.value)
                                        }
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Input
                                        id="stock"
                                        name="stock"
                                        placeholder="Stock"
                                        label="Stock:"
                                        type="number"
                                        error={errors.stock}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <InputSelectGroup
                                        id="weight"
                                        name="weight"
                                        placeholder="Weight"
                                        label="Weight:"
                                        type="text"
                                        error={errors.weight}
                                        readOnly={readOnly}
                                        selectOptions={[
                                            {
                                                label: "kg",
                                                value: "kg",
                                            },
                                            {
                                                label: "pound",
                                                value: "pound",
                                            },
                                        ]}
                                        selectName="weightUnit"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Input
                                        id="price"
                                        name="price"
                                        placeholder="price"
                                        label="Price:"
                                        type="number"
                                        error={errors.price}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Input
                                        id="offerPrice"
                                        name="offerPrice"
                                        placeholder="OfferPrice"
                                        label="OfferPrice:"
                                        type="number"
                                        error={errors.offerPrice}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Input
                                        id="order"
                                        name="order"
                                        placeholder="order"
                                        label="Order:"
                                        type="number"
                                        error={errors.order}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Input
                                        id="offerStart"
                                        name="offerStart"
                                        placeholder="Start date"
                                        label="Offer Start At:"
                                        type="date"
                                        error={errors.offerStart}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Input
                                        id="offerEnd"
                                        name="offerEnd"
                                        placeholder="End date"
                                        label="Offer End At:"
                                        type="date"
                                        error={errors.offerEnd}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <h6 className="fw-bold mb-2">Size</h6>
                                <div className="col-sm-4">
                                    <InputSelectGroup
                                        id="height"
                                        name="height"
                                        placeholder="height"
                                        label="Height:"
                                        error={errors.height}
                                        readOnly={readOnly}
                                        type="text"
                                        selectOptions={[
                                            {
                                                label: "cm",
                                                value: "cm",
                                            },
                                            {
                                                label: "inches",
                                                value: '"',
                                            },
                                        ]}
                                        selectName="heightUnit"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <InputSelectGroup
                                        id="width"
                                        name="width"
                                        placeholder="width"
                                        label="Width:"
                                        error={errors.width}
                                        readOnly={readOnly}
                                        type="text"
                                        selectOptions={[
                                            {
                                                label: "cm",
                                                value: "cm",
                                            },
                                            {
                                                label: "inches",
                                                value: '"',
                                            },
                                        ]}
                                        selectName="widthUnit"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <InputSelectGroup
                                        id="depth"
                                        name="depth"
                                        placeholder="depth"
                                        label="Depth:"
                                        error={errors.depth}
                                        readOnly={readOnly}
                                        type="text"
                                        selectOptions={[
                                            {
                                                label: "cm",
                                                value: "cm",
                                            },
                                            {
                                                label: "inches",
                                                value: '"',
                                            },
                                        ]}
                                        selectName="depthUnit"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <FileUploader
                                        id="images1"
                                        label="Product Images:"
                                        value={variantImages}
                                        name="images1"
                                        multiple={true}
                                        onChange={handleVariantImages}
                                        disabled={readOnly}
                                        error={errors.images}
                                        acceptVideo={true}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    {variantImagesPreview.length > 0 && (
                                        <>
                                            <span className="fw-semibold">
                                                Preview Images:
                                            </span>{" "}
                                            <br />
                                            <div className="row">
                                                {variantImagesPreview.map(
                                                    (image, index) => {
                                                        return (
                                                            <div
                                                                className={`position-relative d-inline-block me-3 mt-3 col-${
                                                                    image.type ==
                                                                    "video"
                                                                        ? 4
                                                                        : 2
                                                                }`}
                                                                key={image.url}
                                                            >
                                                                {image.type ===
                                                                "image" ? (
                                                                    <img
                                                                        src={
                                                                            image.url
                                                                        }
                                                                        className="py-2"
                                                                        width={
                                                                            100
                                                                        }
                                                                        // height={100}
                                                                    />
                                                                ) : (
                                                                    <video
                                                                        src={
                                                                            image.url
                                                                        }
                                                                        className="py-2"
                                                                        controls
                                                                        width={
                                                                            180
                                                                        }
                                                                        // height={100}
                                                                    />
                                                                )}
                                                                <i
                                                                    className="position-absolute top-0 start-0 ms-2 badge border border-light rounded-circle bg-danger p-2 bi bi-archive"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        handleOnDeleteImage(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="visually-hidden">
                                                                        unread
                                                                        messages
                                                                    </span>
                                                                </i>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="col-sm-12">
                                    {existingImages?.length > 0 && (
                                        <>
                                            <span className="fw-semibold">
                                                Existing Images:
                                            </span>{" "}
                                            <br />
                                            <SortableList
                                                onSortEnd={onSortEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                <div className="row">
                                                    {existingImages.map(
                                                        (image) => {
                                                            const isVideo =
                                                                videoTypes.includes(
                                                                    image
                                                                        ?.split(
                                                                            "."
                                                                        )
                                                                        .pop()
                                                                );
                                                            return (
                                                                <SortableItem
                                                                    key={image}
                                                                >
                                                                    <div
                                                                        className={`item d-inline-block me-3 mt-3 col-${
                                                                            isVideo
                                                                                ? 4
                                                                                : 2
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className={`position-relative `}
                                                                        >
                                                                            {videoTypes.includes(
                                                                                image
                                                                                    ?.split(
                                                                                        "."
                                                                                    )
                                                                                    .pop()
                                                                            ) ? (
                                                                                <video
                                                                                    src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                                                                                    className="py-2"
                                                                                    width={
                                                                                        180
                                                                                    }
                                                                                    controls
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                                                                                    className="py-2"
                                                                                    width={
                                                                                        100
                                                                                    }
                                                                                />
                                                                            )}

                                                                            <i
                                                                                className={`position-absolute mt-3 ms-2 top-0 start-0 translate-middle badge border border-light rounded-circle bg-danger p-2 bi bi-archive`}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleOnDeleteExistingImage(
                                                                                        image
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span className="visually-hidden">
                                                                                    unread
                                                                                    messages
                                                                                </span>
                                                                            </i>
                                                                        </div>{" "}
                                                                    </div>
                                                                </SortableItem>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </SortableList>
                                        </>
                                    )}
                                </div>
                                <div className="col-sm-6">
                                    <Select
                                        id="sizeId"
                                        label="Size Type:"
                                        name="sizeId"
                                        data={sizes}
                                        readOnly={readOnly}
                                        onChange={handleSizeChange}
                                        default={sizeType}
                                    />
                                    {errors.sizeId && (
                                        <span className="d-inline-block text-danger mt-2">
                                            {errors.sizeId}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-6">
                                    <Input
                                        id="sku"
                                        name="sku"
                                        placeholder="Sku"
                                        label="Sku:"
                                        readOnly={readOnly}
                                        error={errors.sku}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="d-flex justify-content-between">
                            <div>
                                {readOnly ? (
                                    <button
                                        className="btn btn-primary rounded-1 mt-3"
                                        onClick={() => toggle(index)}
                                    >
                                        <i className="bi bi-pencil-square pe-1"></i>
                                        Edit
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary rounded-1 mt-3"
                                        disabled={isSaving}
                                        onClick={handleCreateVariant}
                                    >
                                        {isSaving && (
                                            <span
                                                class="spinner-border spinner-border-sm me-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        {variantId ? "Update" : "Save"}
                                    </button>
                                )}
                                {!variantId ? (
                                    <button
                                        className="btn btn-secondary rounded-1 mt-3 ms-3"
                                        onClick={() => onDiscard(index)}
                                    >
                                        Discard
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-danger rounded-1 mt-3 ms-3"
                                        onClick={() =>
                                            handleOnDelete(
                                                productId,
                                                variantId,
                                                index
                                            )
                                        }
                                    >
                                        <i className="bi bi-archive pe-1"></i>
                                        Delete
                                    </button>
                                )}
                            </div>
                            <div>
                                <button
                                    className="btn btn-primary rounded-1 mt-3 ms-3"
                                    onClick={onAddMore}
                                >
                                    <i className="bi bi-plus"></i>Add more
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Variant;

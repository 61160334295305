import React from 'react'

function PageContent(props) {
    return (
        <section className="p-4 bg-white rounded-3">
            {
                (props.title || props.subtitle)
                &&
                (
                    <div className="row">
                        <div className="col-12">
                            <h6 className="fw-bold my-0">{(props.title === true) ? "Basic Information" : props.title }</h6>
                            <p>{ (props.subtitle === true) ? "Fill all information below" : props.subtitle}</p>
                        </div>
                    </div>
                )
            }

            {props.children}
        </section>
    )
}

export default PageContent
import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deleteCoupon, getCoupons } from "api/request.api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";
import moment from "moment/moment";

function Index() {
    const navigate = useNavigate();
    const columns = useMemo(() => [
        {
            Header: "Code",
            accessor: "code",
        },
        {
            Header: "Starts at",
            accessor: "starts",
            Cell: ({ cell }) => getDate(cell.value),
        },
        {
            Header: "Ends at",
            accessor: "ends",
            Cell: ({ cell }) => getDate(cell.value),
        },
        {
            Header: "Is Percentage",
            accessor: "isPercentage",
            Cell: ({ cell }) => (cell.value ? "Percentage" : "Amount"),
        },
        {
            Header: "Value",
            accessor: "value",
        },
        {
            Header: "Max Discount Amount",
            accessor: "maxDiscountAmount",
        },
        {
            Header: "Min Cart Amount",
            accessor: "minCartAmount",
        },
        {
            Header: "Actions",
            accessor: "_id",
            Cell: ({ cell }) => (
                <>
                    <Button
                        type="button"
                        variant="info"
                        className="ms-2"
                        icon="bi bi-pencil-square"
                        onClick={() =>
                            navigate(`/admin/coupon/edit/${cell.value}`)
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="ms-2 text-light"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />
                </>
            ),
        },
    ]);

    const [coupon, setCoupon] = useState([]);

    const getDate = (date) => {
        const desiredFormat = "YYYY-MM-DD"; // Desired format
        return moment(date).format(desiredFormat);
    };

    async function fetchCoupon() {
        const { data } = await getCoupons();
        setCoupon(data);
    }

    const handleDeleteCoupon = async (couponId) => {
        const data = await deleteCoupon(couponId);
        if (data.success) {
            fetchCoupon();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this coupon?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteCoupon(id);
            }
        });
    };

    useEffect(() => {
        fetchCoupon();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Coupon List" />
            <PageContent>
                <div className="d-flex flex-row-reverse">
                    <Link
                        className="btn btn-primary"
                        role="button"
                        to="/admin/coupon/create"
                    >
                        Create
                    </Link>
                </div>
                <Table columns={columns} data={coupon} filter={true} />
            </PageContent>
        </div>
    );
}

export default Index;

import React from "react";
import { Bar } from "react-chartjs-2";

const PaymentBarChart = ({ salesData }) => {
    const months = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "June",
        7: "July",
        8: "Aug",
        9: "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec",
    };
    const data = {
        labels: Object.keys(salesData).map(el => months[el]),
        datasets: [
            {
                label: "Amount",
                data: Object.values(salesData),
                backgroundColor: "blue",
                borderColor: "blue",
                borderWidth: 1,
                barThickness: 10,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            title: {
                display: false,
                text: "Payment by month",
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="card p-2">
            <div className="d-flex justify-content-between">
                <h4 className="fw-semibold">Payment</h4>
                <h5 className="fw-normal me-2">By month</h5>
            </div>
            <div className="card-body">
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default PaymentBarChart;

import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deleteProduct, getProducts } from "api/request.api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";

function Index() {
    const navigate = useNavigate();

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Thumbnail",
            accessor: "thumbnail",
            Cell: ({ cell }) => (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/${cell.value}`}
                    alt="User"
                    width="100"
                />
            ),
        },
        {
            Header: "Category",
            accessor: "category.name",
        },
        {
            Header: "Variants",
            accessor: "variant",
            Cell: ({ cell }) => (
                <ol className="list-group list-group-numbered border-0 list-group-flush">
                    {cell?.value?.map((item, index) => (
                        <li
                            className="list-group-item d-flex align-items-start bg-transparent"
                            key={index}
                        >
                            <div className="me-auto">
                                <div className="fw-semibold">{item?.name}</div>
                                SKU: {item?.sku}<br/>
                                Price: ${item?.price}
                            </div>
                            <span
                                className={`badge text-bg-${
                                    item?.stock > 0 ? "secondary" : "danger"
                                }`}
                            >
                                {item?.stock} in stock
                            </span>
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            Header: "Actions",
            accessor: "_id",
            Cell: ({ cell }) => (
                <>
                    <Button
                        type="button"
                        variant="info"
                        className="ms-2"
                        icon="bi bi-pencil-square"
                        onClick={() =>
                            window.open(`/admin/product/edit/${cell.value}`, "_blank")
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="ms-2"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />
                </>
            ),
        },
    ]);

    const [products, setProducts] = useState([]);

    async function fetchProducts() {
        const { data } = await getProducts();
        setProducts(data);
    }

    const handleDeleteProduct = async (id) => {
        const data = await deleteProduct(id);
        if (data.success) {
            fetchProducts();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this product?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteProduct(id);
            }
        });
    };
    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Products List" />
            <PageContent>
                <div className="d-flex flex-row-reverse">
                    <Link
                        className="btn btn-primary"
                        role="button"
                        to="/admin/product/create"
                    >
                        Create
                    </Link>
                </div>
                <Table columns={columns} data={products} filter={true} />
            </PageContent>
        </div>
    );
}

export default Index;

import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deleteReview, getReviews, updateReview } from "api/request.api";
import { Button } from "components";
import Swal from "sweetalert2";

function ReviewIndex() {

    const changeFeaturedReview = async (reviewId, isFeatured) => {
         await updateReview(reviewId, {
            isFeatured: !isFeatured,
        });
        fetchReviews();
    };
    
    const columns = useMemo(() => [
        {
            Header: "Product",
            accessor: "productId.name",
        },
        {
            Header: "Fullname",
            accessor: "firstname",
            Cell: ({ row }) =>
                row.original.firstname + " " + row.original.lastname,
        },
        {
            Header: "Is Featured",
            accessor: "isFeatured",
            Cell: ({ cell, row }) => (
                <button
                    type="button"
                    className={`btn btn-sm btn-${
                        cell.value ? "primary" : "secondary"
                    }`}
                    onClick={() =>
                        changeFeaturedReview(row.original._id, cell.value)
                    }
                >
                    {cell.value ? "Yes" : "No"}
                </button>
            ),
        },
        {
            Header: "Rating",
            accessor: "rating",
        },
        {
            Header: "review",
            accessor: "review",
            Cell: ({ cell }) =>
                cell.value ? cell.value.slice(0, 150) + "..." : "",
        },
        {
            Header: "Actions",
            accessor: "_id",
            Cell: ({ cell, row }) => (
                <>
                    <Button
                        type="button"
                        variant="primary"
                        className="mb-2"
                        icon="bi bi-eye"
                        onClick={() => handleViewReview(row.original.review)}
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="text-light"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />
                </>
            ),
        },
    ]);

    const [reviews, setReviews] = useState([]);

    // const getDate = (date) => {
    //     const desiredFormat = 'YYYY-MM-DD'; // Desired format
    //     return moment(date).format(desiredFormat);
    // }

    const handleViewReview = (message) => {
        Swal.fire({
            title: "Review",
            html: message,
            showCloseButton: true,
            width: "600px",
        });
    };

    async function fetchReviews() {
        const { data } = await getReviews();
        setReviews(data);
    }

    const handleDeleteReview = async (reviewId) => {
        const data = await deleteReview(reviewId);
        if (data.success) {
            fetchReviews();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this review?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteReview(id);
            }
        });
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Reviews List" />
            <PageContent>
                <Table columns={columns} data={reviews} filter={true} />
            </PageContent>
        </div>
    );
}

export default ReviewIndex;

import React, { useEffect, useState } from "react";
import { Input, Switch, Button, PageHead, PageContent } from "components";
import { editCoupon, getCoupon } from "api/request.api";
import { useNavigate, useParams } from "react-router-dom";
import Joi from "joi";
import validateField from "helpers/validateData";
import editCouponSchema from "schema/schema.coupom.edit";

function Edit() {
    const [data, setData] = useState({
        code: "",
        starts: "",
        ends: "",
        isPercentage: false,
        value: "",
        maxDiscountAmount: "",
        minCartAmount: "",
    });
    const [errors, setErrors] = useState({});

    const couponId = useParams().id;
    const navigate = useNavigate();
      
    const fetchCoupon = async () => {
        const { data } = await getCoupon(couponId);
        setData({
            code: data.code,
            starts: getFormattedDate(data.starts),
            ends: getFormattedDate(data.ends),
            isPercentage: data.isPercentage,
            value: data.value,
            maxDiscountAmount: data.maxDiscountAmount,
            minCartAmount: data.minCartAmount,
        });
    };

    useEffect(() => {
        fetchCoupon();
    }, []);

    const getFormattedDate = (date) => {
        return date.split("T")[0];
    };

    const handleChange = (e) => {
        const { name, type } = e.target;
        let { value } = e.target;

        if (type === "checkbox") value = e.target.checked;
        if (name === "code") value = value.toUpperCase();

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fieldErrors = validateField(editCouponSchema, data);
        setErrors(fieldErrors);

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }
        const result = await editCoupon(couponId, data);
        if (result.success) {
            navigate("/admin/coupon");
        } else {
            setErrors({
                ...errors,
                code: result?.data?.message,
            });
        }
    };

    return (
        <div className="p-4">
            <PageHead title="Update Coupon" />

            <PageContent title subtitle>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-4">
                            <Input
                                id="code"
                                name="code"
                                placeholder="Enter unique code"
                                label="Code:"
                                type="text"
                                value={data.code}
                                error={errors.code}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Input
                                id="starts"
                                name="starts"
                                label="Start Date:"
                                type="date"
                                value={data.starts}
                                error={errors.starts}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Input
                                id="ends"
                                name="ends"
                                label="End Date:"
                                type="date"
                                error={errors.ends}
                                value={data.ends}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-3">
                            <Input
                                id="value"
                                name="value"
                                label="Value:"
                                type="number"
                                value={data.value}
                                error={errors.value}
                                onChange={handleChange}
                                placeholder="Enter value"
                            />
                        </div>
                        <div className="col-sm-1">
                            <Switch
                                id="isPercentage"
                                name="isPercentage"
                                label="Is Percentage:"
                                checked={data.isPercentage}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Input
                                id="maxDiscountAmount"
                                name="maxDiscountAmount"
                                label="Max Discount Amount:"
                                type="number"
                                value={data.maxDiscountAmount}
                                error={errors.maxDiscountAmount}
                                onChange={handleChange}
                                placeholder="Enter max discount amount"
                            />
                        </div>
                        <div className="col-sm-4">
                            <Input
                                id="minCartAmount"
                                name="minCartAmount"
                                label="Min Cart Amount:"
                                type="number"
                                value={data.minCartAmount}
                                error={errors.minCartAmount}
                                onChange={handleChange}
                                placeholder="Enter min cart amount"
                            />
                        </div>
                    </div>
                    <Button
                        type="submit"
                        label="Save Changes"
                        variant="primary"
                    />
                </form>
            </PageContent>
        </div>
    );
}

export default Edit;

import Joi from "joi";

const bannerSchema = Joi.object({
    title: Joi.string().min(3).required().label("Title"),
    detail: Joi.string().min(2).required().label("Detail"),
    image: Joi.required().label("Banner Image"),
    isActive: Joi.boolean().required(),
    priority: Joi.number().min(1).required().label("Priority"),
    variant: Joi.string().valid("primary", "secondary").label("Variant"),
    btnPrimaryLabel: Joi.string().allow("").label("Primary Label"),
    btnPrimaryValue: Joi.string().allow("").label("Primary Value"),
    btnSecondaryLabel: Joi.string().allow("").label("Secondary Label"),
    btnSecondaryValue: Joi.string().allow("").label("Secondary Value"),
}).options({ abortEarly: false });

export default bannerSchema;

const validateField = (schema, data, exclude = []) => {
    const { error } = schema.validate(data);
    const tempErrors = {};
    if (error) {
        // Handle validation error
        error.details.forEach((el) => {
            if (!exclude.includes(el.path[0]))
                tempErrors[el.path[0]] = el.message;
        });
    }
    return tempErrors;
};

export default validateField;

import React from "react";

function Datalist(props) {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label">
                {" "}
                {props.label}
            </label>
            <input
                className="form-control"
                list="datalistOptions"
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
            />
            <datalist id="datalistOptions">
                {props.data.map((el) => (
                    <option value={el[props.path]} key={el._id} />
                ))}
            </datalist>
        </div>
    );
}

export default Datalist;

import http from "api/http.api";

export const login = (data) => {
    return http.post(`/auth/login`, data);
};

export const refreshAccessToken = () => {
    return http.get(`/auth/refresh-token`);
};

export const logout = () => {
    return http.delete(`/auth/logout`);
};

// blog routes

export const getBlogs = () => {
    return http.get(`/blog`);
};

export const getBlog = (id) => {
    return http.get(`/blog/${id}`);
};

export const createBlog = (data) => {
    return http.post(`/blog`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const editBlog = (id, data) => {
    return http.put(`/blog/${id}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteBlog = (id) => {
    return http.delete(`/blog/${id}`);
};

// page routes

export const getPages = () => {
    return http.get(`/page`);
};

export const getPage = (id) => {
    return http.get(`/page/${id}`);
};

export const createPage = (data) => {
    return http.post(`/page`, data);
};

export const editPage = (id, data) => {
    return http.put(`/page/${id}`, data);
};

export const deletePage = (id) => {
    return http.delete(`/page/${id}`);
};

// category routes

export const getCategories = () => {
    return http.get(`/category`);
};

export const getAllCategories = () => {
    return http.get(`/category/all`);
};

export const createCategory = (data) => {
    return http.post(`/category`, data);
};

export const editCategory = (id, data) => {
    return http.put(`/category/${id}`, data);
};

export const deleteCategory = (id) => {
    return http.delete(`/category/${id}`);
};

// product routes

export const getProducts = () => {
    return http.get(`/product`);
};

export const getProduct = (id) => {
    return http.get(`/product/${id}`);
};

export const createProduct = (data) => {
    return http.post(`/product`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const editProduct = (id, data) => {
    return http.put(`/product/${id}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteProduct = (id) => {
    return http.delete(`/product/${id}`);
};

export const createVariant = (productId, data) => {
    return http.post(`/product/${productId}/variant`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const editVariant = (id, data) => {
    return http.put(`/variant/${id}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteVariant = (productId, variantId) => {
    return http.delete(`/product/${productId}/variant/${variantId}`);
};

// coupon routes
export const createCoupon = (data) => {
    return http.post(`/coupon`, data);
};

export const getCoupons = () => {
    return http.get(`/coupon`);
};

export const getCoupon = (id) => {
    return http.get(`/coupon/${id}`);
};

export const editCoupon = (id, data) => {
    return http.put(`/coupon/${id}`, data);
};

export const deleteCoupon = (id) => {
    return http.delete(`/coupon/${id}`);
};

// banner routes
export const createBanner = (data) => {
    return http.post(`/banner`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const getBanners = () => {
    return http.get(`/banner`);
};

export const getBanner = (id) => {
    return http.get(`/banner/${id}`);
};

export const editBanner = (id, data) => {
    return http.put(`/banner/${id}`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const deleteBanner = (id) => {
    return http.delete(`/banner/${id}`);
};

// coupon routes
export const createConfig = (data) => {
    return http.post(`/config`, data);
};

export const getConfigs = () => {
    return http.get(`/config`);
};

export const getConfig = (id) => {
    return http.get(`/config/${id}`);
};

export const editConfig = (id, data) => {
    return http.put(`/config/${id}`, data);
};

export const deleteConfig = (id) => {
    return http.delete(`/config/${id}`);
};

// size routes
export const createSize = (data) => {
    return http.post(`/size`, data);
};

export const getSizes = () => {
    return http.get(`/size`);
};

export const getSize = (id) => {
    return http.get(`/size/${id}`);
};

export const editSize = (id, data) => {
    return http.put(`/size/${id}`, data);
};

export const deleteSize = (id) => {
    return http.delete(`/size/${id}`);
};

//contact

export const getContacts = () => {
    return http.get(`/contact`);
};

export const markEnquiryAsRead = (id) => {
    return http.put(`/enquiry/${id}/read`);
};

export const markEnquiryAsUnread = (id) => {
    return http.put(`/enquiry/${id}/unread`);
};

export const markContactAsRead = (id) => {
    return http.put(`/contact/${id}/read`);
};

export const markContactAsUnread = (id) => {
    return http.put(`/contact/${id}/unread`);
};

export const markOrderAsRead = (id) => {
    return http.put(`/order/${id}/read`);
};

export const markOrderAsUnread = (id) => {
    return http.put(`/order/${id}/unread`);
};

export const getReviews = () => {
    return http.get(`/review`);
};

export const getLatestReviews = () => {
    return http.get(`/review/latest`);
};

export const deleteReview = (id) => {
    return http.delete(`/review/${id}`);
};

export const updateReview = (id, data) => {
    return http.put(`/review/${id}`, data);
};

export const getOrders = () => {
    return http.get(`/order`);
};

export const deliverOrder = (id) => {
    return http.put(`/order/${id}/deliver`);
};

export const shipOrder = (id) => {
    return http.put(`/order/${id}/ship`);
};

export const getOrderStat = () => {
    return http.get(`/order/order-stat`);
};

export const getUserStat = () => {
    return http.get(`/user/user-stat`);
};

export const getProductStat = () => {
    return http.get(`/product/product-stat`);
};

export const getEnquiry = () => {
    return http.get(`/enquiry`);
};

export const deleteEnquiry = (id) => {
    return http.delete(`/enquiry/${id}`);
};

export const getNotificationStat = () => {
    return http.get(`/notification`);
};

export const changePassword = (data) => {
    return http.put(`/user/change-password`, data);
};

export const getUsers = () => {
    return http.get(`/user`);
};

export const createAdmin = (data) => {
    return http.post(`/user/register`,data);
};

export const deleteAdminUser = (id) => {
    return http.delete(`/user/delete/${id}`);
};

export const deleteVariantImage = (id, data) => {
    return http.put(`/variant/${id}/delete-image`,data);
}

export const getComments = () => {
    return http.get(`/comment`);
};


export const deleteComment = (id) => {
    return http.delete(`/comment/${id}`);
};
import axios from "axios";
import { toast } from "react-toastify";
import { refreshAccessToken } from "./request.api";

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + "/api/v1",
    timeout: 8000,
    headers: {
        "Content-Type": "application/json",
    },
});

http.interceptors.request.use(function (config) {
    const loginDetail = localStorage.getItem("isLogged");
    if (loginDetail) {
        const token = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        config.headers["Authorization"] =
            config.url.includes("refresh-token") ||
            config.url.includes("logout")
                ? `Bearer ${refreshToken}`
                : `Bearer ${token}`;
    }
    return config;
});

http.interceptors.response.use(
    function (response) {
        if (response.status === 200 || response.status === 201) {
            if (response.data.message !== "Success")
                toast.success(response.data.message);
            return response.data;
        } else return response;
    },
    function (error) {
        const status = error.response?.status;

        if (status === 401) {
            return (window.location.href = "/login");
        }
        if (status >= 400 && status < 490) {
            // console.log(error.response.data.message);
            toast.error(error.response.data.error);
        }

        if (status === 498) {
            // Access token expired, refresh it and retry the original request
            return refreshAccessToken()
                .then(({ data }) => {
                    localStorage.setItem("accessToken", data.accessToken);
                    // Update the original request with the new access token
                    error.config.headers[
                        "Authorization"
                    ] = `Bearer ${data.accessToken}`;
                    // // Retry the original request
                    return http(error.config);
                })
                .catch((refreshError) => {
                    // Handle the refresh token error
                    return Promise.reject(refreshError);
                });
        }

        return error.response;
    }
);

const httpApi = {
    get: http.get,
    post: http.post,
    put: http.put,
    delete: http.delete,
};

export default httpApi;

import React from "react";

function InputSelectGroup(props) {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label fw-semibold">
                {props.label}
            </label>
            <div class="input-group">
                <input
                    type={props.type}
                    className="form-control rounded-1"
                    id={props.id}
                    placeholder={props.placeholder}
                    name={props.name}
                    readOnly={props.readOnly || false}
                />
                <select class="form-select" aria-label="Default select example" name={props.selectName}>
                    <option disabled>Size</option>
                    {props.selectOptions.map((item) => (
                        <option value={item.value}>{item.label}</option>
                    ))}
                </select>
            </div>
            {props.error && (
                <span className="text-danger d-inline-block pt-2">
                    {props.error}
                </span>
            )}
        </div>
    );
}

export default InputSelectGroup;

import React, { useRef, useState } from "react";
import {
    Input,
    Switch,
    Button,
    PageHead,
    PageContent,
    Editor,
} from "components";
import { createConfig, editConfig, getConfig } from "api/request.api";
import { useNavigate, useParams } from "react-router-dom";
import Joi from "joi";
import validateField from "helpers/validateData";

function ConfigEdit() {
    const [data, setData] = useState({
        title: "",
        label: "",
        value: "",
        status: false,
    });

    const [errors, setErrors] = useState({});
    const [content, setContent] = useState("");
    const editorRef = useRef(null);

    const schema = Joi.object({
        value: Joi.required().label("Value"),
        label: Joi.string().required().min(3).label("Label"),
    }).options({ abortEarly: false });

    const configId = useParams().id;
    const navigate = useNavigate();

    const fetchConfig = async () => {
        const { data } = await getConfig(configId);
        setData({
            title: data.title,
            label: data.label,
            value: data.value ? data.value : "",
            status: data.status,
        });

        setContent(data.value)
    };

    useState(() => {
        fetchConfig();
    }, []);

    const handleChange = (e) => {
        const { name, type } = e.target;
        let { value } = e.target;

        if (type === "checkbox") value = e.target.checked;

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const config = {
            ...data,
            value: isContentRaw() ? editorRef.current.getContent() : data.value
        };

        const fieldErrors = validateField(schema, config, ["status", "title"]);
        setErrors({ ...fieldErrors });

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }
        const result = await editConfig(configId, config);
        if (result.success) {
            navigate("/admin/config");
        }
    };

    const isContentRaw = () => {
        return (
            data.title.includes("offer") || data.title.includes("homeContent")
        );
    };
    return (
        <div className="p-4">
            <PageHead title="Config Edit" />

            <PageContent title subtitle>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-2">
                            <Input
                                id="title"
                                name="title"
                                placeholder="Enter unique title"
                                label="Title:"
                                type="text"
                                value={data.title}
                                onChange={handleChange}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-sm-5">
                            <Input
                                id="label"
                                name="label"
                                label="Label:"
                                type="text"
                                value={data.label}
                                error={errors.label}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Switch
                                id="status"
                                name="status"
                                label="Status:"
                                checked={data.status}
                                onChange={handleChange}
                            />
                        </div>

                        {isContentRaw() ? (
                            <div className="col-sm-12 mb-3">
                                <Editor
                                    id="value"
                                    name="value"
                                    label="Value:"
                                    editorRef={editorRef}
                                    initialValue={content}
                                />

                                {errors.value && (
                                    <span className="d-inline-block text-danger mt-2">
                                        {errors.value}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div className="col-sm-12">
                                <Input
                                    id="value"
                                    name="value"
                                    label="Value:"
                                    type="text"
                                    value={data.value}
                                    error={errors.value}
                                    onChange={handleChange}
                                />
                            </div>
                        )}
                    </div>
                    <Button
                        type="submit"
                        label="Save Changes"
                        variant="primary"
                    />
                </form>
            </PageContent>
        </div>
    );
}

export default ConfigEdit;

import Joi from "joi";

const createCouponSchema = Joi.object({
    code: Joi.string().alphanum().max(20).required(),
    starts: Joi.date()
        .min(new Date().toISOString().split("T")[0])
        .required()
        .label("Start date"),
    ends: Joi.date().min(Joi.ref("starts")).required().label("End date"),
    value: Joi.number().positive().label("Value"),
    maxDiscountAmount: Joi.number().positive().label("Max Discount Amount"),
    minCartAmount: Joi.number()
        .positive()
        .greater(Joi.ref("maxDiscountAmount"))
        .label("Min Cart Amount"),
    isPercentage: Joi.boolean(),
})
    .when(Joi.object({ isPercentage: Joi.boolean().valid(false) }).unknown(), {
        then: Joi.object({
            value: Joi.number()
                .positive()
                .min(Joi.ref("maxDiscountAmount"), Joi.ref("minCartAmount")),
        }),
    })
    .options({ abortEarly: false });

export default createCouponSchema;

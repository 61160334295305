import {
    deleteEnquiry,
    getEnquiry,
    markEnquiryAsRead,
    markEnquiryAsUnread,
} from "api/request.api";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import NotificationContext from "context/NotificationContext";
import Swal from "sweetalert2";
import Table from "./Table";

const LatestEnquiries = () => {
    const [enquiry, setEnquiry] = useState([]);
    const { notification, setNotification } = useContext(NotificationContext);

    const fetchData = async () => {
        const { data } = await getEnquiry();
        setEnquiry(data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Variant",
            accessor: "variantId",
            Cell: ({ cell }) => {
                const size = cell.value?.size;
                return (
                    <p style={{width: "170px"}}>
                        Name: {cell?.value?.name}
                        <br /> Size: {size?.depth} * {size?.width} * {size?.height}
                    </p>
                );
            },
        },
        {
            Header: "Mark as Read",
            accessor: "isRead",
            Cell: ({ cell, row }) => (
                <Button
                    type="button"
                    variant={cell.value ? "secondary" : "primary"}
                    icon={
                        cell.value
                            ? "bi bi-envelope-open-fill"
                            : "bi bi-envelope-fill"
                    }
                    title={cell.value ? "Mark as unread" : "Mark as read"}
                    onClick={() =>
                        cell.value
                            ? markAsUnread(row.original._id)
                            : markAsRead(row.original._id)
                    }
                />
            ),
        },
        {
            Header: "Actions",
            accessor: "message",
            Cell: ({ cell, row }) => (
                <>
                    <Button
                        type="button"
                        variant="primary"
                        className="me-2 mt-2"
                        icon="bi bi-eye"
                        onClick={() =>
                            handleViewContact(cell.value, row.original._id)
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="mt-2"
                        icon="bi bi-trash"
                        onClick={() => onDelete(row.original._id)}
                    />
                </>
            ),
        },
    ]);

    const updateEnquiry = (id, state) => {
        const enquiryIndex = enquiry.findIndex((item) => item._id === id);
        const enquiries = [...enquiry];
        enquiries[enquiryIndex].isRead = state;
        setEnquiry(enquiries);
    };

    const markAsRead = async (id) => {
        await markEnquiryAsRead(id);
        setNotification({
            ...notification,
            enquiry: notification.enquiry - 1,
        });
        updateEnquiry(id, true);
    };

    const markAsUnread = async (id) => {
        await markEnquiryAsUnread(id);
        setNotification({
            ...notification,
            enquiry: notification.enquiry + 1,
        });
        updateEnquiry(id, false);
    };

    const handleViewContact = (message, id) => {
        Swal.fire({
            title: "Message",
            html: message,
            showCloseButton: true,
            width: "600px",
        });
        markAsRead(id);
    };

    const handleDeleteEnquiry = async (id) => {
        const data = await deleteEnquiry(id);
        if (data.success) {
            fetchData();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this enquiry record?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteEnquiry(id);
            }
        });
    };

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Latest enquiries</h4>
                <div className="table-responsive latest-section">
                    <Table columns={columns} data={enquiry.slice(0, 5) || []} />
                </div>

                <Link
                    to="/admin/enquiry"
                    className="card-link text-center text-primary d-block"
                >
                    Show More
                </Link>
            </div>
        </div>
    );
};

export default LatestEnquiries;
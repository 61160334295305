import React, { useState, createContext } from "react";

const UploadContext = createContext();
export default UploadContext;

export function UploadContextProvider({ children }) {
    const [isUploading, setIsUploading] = useState(false);

    return (
        <UploadContext.Provider value={{ isUploading, setIsUploading }}>
            {children}
        </UploadContext.Provider>
    );
}

import Input from "components/Input";
import React, { useEffect, useState } from "react";
import CategoryTree from "./CategoryTree";
import Datalist from "components/Datalist";

import Swal from "sweetalert2";
import {
    createCategory,
    deleteCategory,
    editCategory,
    getAllCategories,
    getCategories,
} from "api/request.api";
import { toast } from "react-toastify";
import Select from "components/Select";
import { Switch } from "components";
import { Link } from "react-router-dom";

function Index() {
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState({});
    const [allCategories, setAllCategories] = useState([]);

    const [data, setData] = useState({
        name: "",
        priority: "",
        highlight: false,
    });

    const fetchAllCategories = async () => {
        const { data } = await getAllCategories();
        setAllCategories(data);
    };

    const fetchCategories = async () => {
        const { data } = await getCategories();
        setCategories(data);
    };

    useEffect(() => {
        fetchAllCategories();
        fetchCategories();
    }, []);

    const [isEdit, setIsEdit] = useState(false);
    const [parentCategory, setParentCategory] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const handleOnChange = ({ currentTarget }) => {
        const { value, name } = currentTarget;

        if (name === "highlight") {
            setData({
                ...data,
                [name]: !data.highlight,
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    const handleCategoryChange = (element) => {
        setParentCategory(element.value);
    };

    const resetData = () => {
        setIsEdit(false);
        setData({ name: "", priority: "", highlight: false });
        setParentCategory(null);
        setSelectedCategoryId(null);
    };

    const handleSaveCategory = async () => {
        if (parentCategory) {
            const index = getCategoryIndex(parentCategory);

            if (index === -1) {
                return toast.error("Please select valid parent category");
            }

            const parentId = allCategories[index]._id;
            data.parent_id = parentId;
        }

        if (data.name === '') {
            return toast.error("Please enter the name of category");
        }
        if (!data.priority) {
            return toast.error("Please select priority for category");
        }

        const result = isEdit
            ? await editCategory(selectedCategoryId, data)
            : await createCategory(data);

        if (result.success) {
            resetData();
            fetchAllCategories();
            fetchCategories();
        } else {
            setError(result?.data?.message);
        }
    };

    const getCategoryIndex = (categoryName) => {
        const index = allCategories.findIndex((el) => el.name === categoryName);
        return index;
    };

    const handleEditClick = (category, parentId) => {
        setData({
            name: category.name,
            highlight: category.highlight,
            priority: category.priority,
        });
        setSelectedCategoryId(category._id);
        setIsEdit(true);

        if (parentId) {
            let selectedCategory = allCategories.find(
                (cat) => cat._id === parentId
            );
            setParentCategory(selectedCategory.name);
        } else {
            setParentCategory(null);
        }
    };

    const handleOnDelete = async (category) => {
        const data = await deleteCategory(category._id);

        if (data?.success) {
            resetData();
            fetchAllCategories();
            fetchCategories();
        } else {
            toast.error(data?.data?.message);
        }
    };

    const handleDeleteClick = (category) => {
        Swal.fire({
            title: `Do you want to delete ${category.name}-category?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleOnDelete(category);
            }
        });
    };

    return (
        <div className="p-4">
            <section>
                <div className="row">
                    <div className="col-sm-6 mb-3">
                        <h4 className="fw-semibold">Add Category</h4>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin">Home</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/admin/category">Category</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="p-4 bg-white rounded-3">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold my-0">New Category</h6>
                        <p>Fill all information below</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <Input
                            id="name"
                            name="name"
                            placeholder="Enter category name"
                            label="Name:"
                            onChange={handleOnChange}
                            value={data.name}
                            error={error.name}
                        />
                    </div>

                    <div className="col-sm-4">
                        <Select
                            id="parentCategory"
                            label="Parent Category:"
                            name="parentCategory"
                            onChange={handleCategoryChange}
                            data={allCategories
                                .filter((cat) => cat._id !== selectedCategoryId)
                                .map((el) => {
                                    return { value: el.name, label: el.name };
                                })}
                            default={{
                                value: parentCategory ?? "",
                                label: parentCategory ?? "",
                            }}
                        />
                    </div>
                    <div className="col-sm-1">
                        <Switch
                            id="highlight"
                            name="highlight"
                            label="Show in Navbar:"
                            checked={data?.highlight}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className="col-sm-3">
                        <Input
                            id="priority"
                            name="priority"
                            placeholder="Enter priority"
                            label="Priority:"
                            type="number"
                            onChange={handleOnChange}
                            value={data?.priority}
                        />
                    </div>
                </div>

                <button
                    className="btn btn-primary rounded-1 mb-2"
                    onClick={handleSaveCategory}
                >
                    Save Changes
                </button>
                {isEdit && (
                    <button
                        className="btn btn-danger rounded-1 mb-2 ms-3"
                        onClick={resetData}
                    >
                        Cancel
                        <i className="bi bi-pencil-square ps-2"></i>
                    </button>
                )}

                <h5 className="fw-semibold mt-3">All Category</h5>
                <ol className="list-group list-group-flush">
                    {categories &&
                        categories.map((category, index) => (
                            <CategoryTree
                                category={category}
                                key={category._id}
                                onEdit={handleEditClick}
                                onDelete={handleDeleteClick}
                                parentId={null}
                                number={`${index + 1}`}
                            />
                        ))}
                </ol>
            </section>
        </div>
    );
}

export default Index;

import Input from "components/Input";
import React, { useEffect, useRef, useState } from "react";

import FileUploader from "components/FileUploader";
import Editor from "components/Editor";
import { createBlog } from "api/request.api";
import { useNavigate } from "react-router-dom";
import blogSchema from "schema/schema.blog";

const Joi = require("joi");

function Create() {
    const [thumbnail, setThumbnail] = useState();
    const [errors, setErrors] = useState({});
    const [thumbnailPreview, setThumbnailPreview] = useState();
    const [title, setTitle] = useState("");
    const editorRef = useRef(null);

    const navigate = useNavigate();

    const handleThumbnailChange = ({ currentTarget }) => {
        setThumbnail(currentTarget.files[0]);
    };

    useEffect(() => {
        if (!thumbnail) {
            setThumbnailPreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(thumbnail);
        setThumbnailPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [thumbnail]);

    const validateField = (data) => {
        const { error } = blogSchema.validate(data);
        const tempErrors = {};
        if (error) {
            error.details.forEach((el) => {
                tempErrors[el.path[0]] = el.message;
            });
        }
        return tempErrors;
    };

    const handleCreateBlog = async () => {
        const blog = {
            title,
            thumbnail,
            description: editorRef.current.getContent(),
        };

        const fieldErrors = validateField(blog);

        setErrors(fieldErrors);
        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }

        const formData = new FormData();

        Object.keys(blog).forEach((key) => {
            formData.append(key, blog[key]);
        });

        const result = await createBlog(blog);
        if (result.success) {
            navigate("/admin/blog");
        }
    };

    return (
        <div className="p-4">
            <section>
                <div className="row">
                    <div className="col-sm-6 mb-3">
                        <h4 className="fw-semibold">Add Blog</h4>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="#">Blogs</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="p-4 bg-white rounded-3">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold my-0">Basic Information</h6>
                        <p>Fill all information below</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Input
                            id="title"
                            name="title"
                            placeholder="Enter the title"
                            label="Title:"
                            type="text"
                            onChange={(e) => setTitle(e.target.value)}
                            error={errors.title}
                        />
                    </div>
                    <div className="col-sm-6">
                        <FileUploader
                            id="thumbnail1"
                            value={thumbnail}
                            label="Thumbnail:"
                            multiple={false}
                            onChange={handleThumbnailChange}
                            error={errors.thumbnail}
                        />
                    </div>
                    <div className="col-sm-6">
                        {thumbnailPreview && (
                            <>
                                <span className="fw-semibold">
                                    Preview Thumbnail
                                </span>
                                <br />
                                <img
                                    src={thumbnailPreview}
                                    height={100}
                                    width={100}
                                />
                            </>
                        )}
                    </div>
                    <div className="col-sm-12 mb-3">
                        <Editor
                            id="description"
                            name="description"
                            label="Description:"
                            editorRef={editorRef}
                            initialValue=""
                        />

                        {errors.description && (
                            <span className="d-inline-block text-danger mt-2">
                                {errors.description}
                            </span>
                        )}
                    </div>
                </div>
                <button
                    className="btn btn-primary rounded-1"
                    onClick={handleCreateBlog}
                >
                    Save
                </button>
            </section>
        </div>
    );
}

export default Create;

import Table from "components/Table";
import Chart from "chart.js/auto";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Doughnot from "./SalesDoughnot";
import BarChart from "./PaymentBarChart";

import {
    getLatestReviews,
    getOrderStat,
    getProductStat,
    getUserStat,
} from "api/request.api";
import LatestEnquiries from "components/LatestEnquiries";
import OrderIndex from "pages/orders/Index";

function Dashboard() {
    const [orderStat, setOrderStat] = useState({});
    const [userStat, setUserStat] = useState({});
    const [productStat, setProductStat] = useState({});

    const fetchOrderStat = async () => {
        const { data } = await getOrderStat();
        setOrderStat(data);
    };

    const fetchUserStat = async () => {
        const { data } = await getUserStat();
        setUserStat(data);
    };

    const fetchProductStat = async () => {
        const { data } = await getProductStat();
        setProductStat(data);
    };

    useEffect(() => {
        fetchOrderStat();
        fetchUserStat();
        fetchProductStat();
    }, []);

    return (
        <div className="p-4">
            <div className="row">
                <div className="col-sm-4">
                    <DashboardTopCard
                        label="Weekly Sales"
                        icon="graph-up-arrow"
                        number={
                            (orderStat?.weeklySales?.length > 0 &&
                                orderStat?.weeklySales[0]?.count) ||
                            0
                        }
                        // message="Increased by 10%"
                        variant="variant-1"
                    />
                </div>
                <div className="col-sm-4">
                    <DashboardTopCard
                        label="Weekly orders"
                        icon="bookmark"
                        number={
                            (orderStat?.weeklySales?.length > 0 &&
                                orderStat?.weeklySales[0]?.totalSalesAmount) ||
                            0
                        }
                        // message="Increased by 30%"
                        variant="variant-2"
                    />
                </div>
                <div className="col-sm-4">
                    <DashboardTopCard
                        label="Visitors online"
                        icon="geo-alt"
                        number={Math.max(
                            orderStat?.weeklySales?.length > 0 &&
                                orderStat?.weeklySales[0]?.count,
                            userStat?.monthlyNewUser,
                            0
                        )}
                        // message="Decreased by 19%"
                        variant="variant-3"
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-4 mb-3">
                    <Doughnot orderData={orderStat?.shippingStat || {}} />
                </div>
                <div className="col-sm-8 mb-3">
                    <BarChart salesData={orderStat?.salesByMonth || {}} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-7">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <InfoCard1
                                label="Customer"
                                number={userStat?.monthlyNewUser || 0}
                                isIncreased={true}
                                displayPercentage={false}
                                message="Since Last Month"
                                percentage="20.55"
                                icon="person"
                            />
                        </div>
                        <div className="col-sm-6">
                            <InfoCard1
                                label="Orders"
                                number={orderStat?.orders?.count || 0}
                                isIncreased={orderStat?.orders?.percent > 0}
                                message="Since Last Month"
                                percentage={
                                    (orderStat?.orders?.percent || 0) + "%"
                                }
                                icon="cart"
                                displayPercentage={true}
                            />
                        </div>
                        <div className="col-sm-6">
                            <InfoCard1
                                label="Revenue"
                                number={`$ ${orderStat?.revenue?.amount || 0}`}
                                isIncreased={orderStat?.revenue?.percent > 0}
                                message="Since Last Month"
                                percentage={
                                    (orderStat?.revenue?.percent ?? "NA") + "%"
                                }
                                icon="currency-dollar"
                                displayPercentage={true}
                            />
                        </div>
                        <div className="col-sm-6">
                            <InfoCard1
                                label="Growth"
                                number={`$ ${orderStat?.growth?.amount || 0}`}
                                isIncreased={orderStat?.growth?.percent > 0}
                                message="Since Last Month"
                                percentage={
                                    (orderStat?.growth?.percent || "N/A") + "%"
                                }
                                icon="graph-up"
                                displayPercentage={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mb-2">
                    <div className="row">
                        <div className="col-sm-6">
                            <InfoCard2
                                label="Welcome"
                                color="orange"
                                icon="person-fill"
                                number={userStat?.total || 0}
                            />
                        </div>
                        <div className="col-sm-6">
                            <InfoCard2
                                label="Shipped Orders this month"
                                color="lightblue"
                                icon="truck"
                                number={orderStat?.shippingStat?.shipped ?? 0}
                            />
                        </div>
                        <div className="col-sm-6">
                            <InfoCard2
                                label="Collections"
                                color="lightgreen"
                                icon="cloud-arrow-down-fill"
                                number={productStat?.productCount || 0}
                            />
                        </div>
                        <div className="col-sm-6">
                            <InfoCard2
                                label="Comments"
                                color="lightpink"
                                icon="chat-dots-fill"
                                number={productStat?.reviewCount || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row mt-3">
                <div className="col-sm-8 mb-3">
                    <VisitAnalytics />
                </div>
                <div className="col-sm-4 mb-3">
                    <TrafficDoughnot />
                </div>
            </div> */}
            <div className="row mt-3">
                <div className="col-sm-6 mb-3">
                    <LatestEnquiries />
                </div>
                <div className="col-sm-6 mb-3">
                    <TopProducts products={productStat?.topProducts} />
                </div>
                <div className="col-12">
                    <OrderIndex />
                </div>
            </div>
        </div>
    );
}

const DashboardTopCard = (props) => {
    const style = {
        "variant-1": {
            background: "rgb(209, 127, 59)",
            background:
                "linear-gradient( 68deg, rgba(209, 127, 59, 0.95) 23%, rgba(217, 9, 205, 0.75) 100%)",
        },
        "variant-2": {
            background: "rgb(59,62,209)",
            background:
                "linear-gradient(79deg, rgba(59,62,209,0.95) 23%, rgba(208,9,217,0.75) 100%)",
        },
        "variant-3": {
            background: "rgb(59,188,209)",
            background:
                "linear-gradient(79deg, rgba(10,121,25,0.95)  15%, rgba(59,188,209,0.76) 100%)",
        },
    };

    return (
        <div
            className="card sales-card border-0 p-3 mb-2"
            style={style[props.variant]}
        >
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div className="fw-normal">{props.label}</div>
                    <i className={`bi bi-${props.icon}`}></i>
                </div>
                <h4 className="mt-3">{props.number}</h4>
                {/* <span className="fw-lighter">{props.message}</span> */}
            </div>
        </div>
    );
};

const InfoCard1 = (props) => {
    return (
        <div className="card border-0 p-3 mb-2">
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <span className="fw-normal">{props.label}</span> <br />
                        <h4 className="my-3 fw-semibold">{props.number}</h4>
                    </div>
                    <div>
                        <i
                            className={`bi text-light p-2 my-3 d-inline-block  bg-info rounded-2 bi-${props.icon}`}
                        ></i>
                    </div>
                </div>

                <div>
                    {props.displayPercentage && (
                        <span
                            className={`fw-semibold text-${
                                props.isIncreased ? "success" : "danger"
                            }`}
                        >
                            <i
                                className={`bi bi-arrow-${
                                    props.isIncreased ? "up" : "down"
                                }`}
                            ></i>
                            {props.percentage}
                        </span>
                    )}
                    &nbsp;
                    <span className="fw-normal ms-3">{props.message}</span>
                </div>
            </div>
        </div>
    );
};

const InfoCard2 = (props) => {
    return (
        <div className="card border-0 p-1 mb-2">
            <div className="card-body text-center">
                <i
                    className={`bi h1 bi-${props.icon}`}
                    style={{ color: props.color }}
                ></i>
                <p className="fw-semibold h3">{props.number}</p>

                <p className="fst-normal">{props.label}</p>
            </div>
        </div>
    );
};

// const LatestReviews = () => {
//     const [data, setData] = useState([]);
//     const fetchData = async () => {
//         const { data } = await getLatestReviews();
//         setData(data);
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);
//     return (
//         <div className="card">
//             <div className="card-body">
//                 <h4 className="card-title">Latest Ratings and Reviews</h4>
//                 <ol className="list-group list-group-numbered list-group-flush">
//                     {data.length > 0 &&
//                         data.map((item) => (
//                             <li
//                                 className="list-group-item d-flex justify-content-between align-items-start"
//                                 key={item._id}
//                             >
//                                 <div className="ms-2 me-auto">
//                                     <div className="fw-bold">
//                                         {item.firstname + " " + item.lastname}
//                                     </div>
//                                     {item.review.substring(0, 50) + "..."}
//                                 </div>
//                                 <span>
//                                     <Ratings count={item.rating} />
//                                 </span>
//                             </li>
//                         ))}
//                 </ol>

//                 <Link
//                     to="/admin/review"
//                     className="card-link text-center text-primary d-block"
//                 >
//                     Show More
//                 </Link>
//             </div>
//         </div>
//     );
// };

const TopProducts = ({ products }) => {
    const columns = useMemo(() => [
        {
            Header: "Product name",
            accessor: "variant.name",
        },
        {
            Header: "Image",
            accessor: "thumbnail",
            Cell: ({ cell }) => (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/${cell.value}`}
                    alt=""
                    height={45}
                    width={45}
                />
            ),
        },
        {
            Header: "In Stock",
            accessor: "variant.stock",
            Cell: ({ cell }) => (
                <span
                    className={`badge badge-pill text-bg-${
                        cell.value <= 0
                            ? "danger"
                            : cell.value <= 5
                            ? "warning"
                            : "success"
                    }`}
                >
                    {cell.value <= 0
                        ? "Out of stock"
                        : cell.value <= 5
                        ? "Limited in stock"
                        : "Available"}
                </span>
            ),
        },
        {
            Header: "Price",
            accessor: "variant.price",
        },
    ]);
    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Top Selling Products</h4>
                <div className="latest-section">
                    <Table columns={columns} data={products || []} />
                </div>
                <Link
                    to="/admin/product"
                    className="card-link text-center text-primary d-block"
                >
                    Show More
                </Link>
            </div>
        </div>
    );
};

// const Ratings = ({ count }) => {
//     const full = Math.floor(count);
//     let half;
//     if (full === count) {
//         half = 5 - full;
//     } else {
//         half = 5 - full - 1;
//     }
//     return (
//         <>
//             {Array.from({ length: full }, (v, i) => {
//                 return <i className="bi bi-star-fill text-warning"></i>;
//             })}
//             {count !== full && <i className="bi bi-star-half text-warning"></i>}
//             {half !== 0 &&
//                 Array.from({ length: half }, (v, i) => {
//                     return <i className="bi bi-star text-warning"></i>;
//                 })}
//         </>
//     );
// };
export default Dashboard;

import React, { useEffect, useState } from "react";
import {
    Input,
    Switch,
    Button,
    PageHead,
    PageContent,
    Select,
    FileUploader,
} from "components";
import { editBanner, getBanner } from "api/request.api";
import createForm from "helpers/createForm";
import { useNavigate, useParams } from "react-router-dom";
import validateField from "helpers/validateData";
import bannerSchema from "schema/schema.banner";

function Edit() {
    const [data, setData] = useState({
        image: null,
        isActive: true,
        priority: "",
        title: "",
        detail: "",
        btnPrimaryLabel: "",
        btnPrimaryValue: "",
        btnSecondaryLabel: "",
        btnSecondaryValue: "",
    });

    const [existingImageUrl, setExistingImageUrl] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");
    const [variant, setVariant] = useState({});
    const [errors, setErrors] = useState({});

    const bannerId = useParams().id;
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, type } = e.target;
        let { value } = e.target;

        if (type === "file" && value) {
            value = e.target.files[0];
            setPreviewUrl(URL.createObjectURL(value));
        }
        if (type === "checkbox") value = e.target.checked;

        setData({
            ...data,
            [name]: value,
        });
    };

    const fetchBanner = async () => {
        const { data } = await getBanner(bannerId);
        setData({
            isActive: data.isActive,
            priority: data.priority,
            title: data.title,
            detail: data.detail,
            btnPrimaryLabel: data.btnPrimaryLabel,
            btnPrimaryValue: data.btnPrimaryValue,
            btnSecondaryLabel: data.btnSecondaryLabel,
            btnSecondaryValue: data.btnSecondaryValue,
        });
        setExistingImageUrl(data.image);
        setVariant({ value: data.variant, label: data.variant });
    };

    useEffect(() => {
        fetchBanner();
    }, []);

    useEffect(() => {
        if (previewUrl) {
            return () => URL.revokeObjectURL(previewUrl);
        }
    }, [previewUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const banner = { ...data };
        banner.variant = variant.value ?? "";

        const fieldErrors = validateField(bannerSchema, banner, ["image"]);
        setErrors({ ...fieldErrors });

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }
        const result = await editBanner(bannerId, createForm(banner));
        if (result.success) {
            navigate("/admin/banner");
        } else {
            setErrors({
                ...errors,
                priority: result?.data?.message,
            });
        }
    };

    return (
        <div className="p-4">
            <PageHead title="Update Banner" />

            <PageContent title subtitle>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <Input
                                id="title"
                                name="title"
                                label="Title:"
                                type="text"
                                value={data.title}
                                error={errors.title}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="detail"
                                name="detail"
                                label="Detail:"
                                type="text"
                                value={data.detail}
                                error={errors.detail}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="btnPrimaryLabel"
                                name="btnPrimaryLabel"
                                label="Primary Label"
                                type="text"
                                value={data.btnPrimaryLabel}
                                onChange={handleChange}
                                error={errors.btnPrimaryLabel}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="btnPrimaryValue"
                                name="btnPrimaryValue"
                                label="Primary Button Value:"
                                placeholder="In href"
                                type="text"
                                value={data.btnPrimaryValue}
                                onChange={handleChange}
                                error={errors.btnPrimaryValue}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="btnSecondaryLabel"
                                name="btnSecondaryLabel"
                                label="Secondary Label"
                                type="text"
                                value={data.btnSecondaryLabel}
                                onChange={handleChange}
                                error={errors.btnSecondaryLabel}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Input
                                id="btnSecondaryValue"
                                name="btnSecondaryValue"
                                label="Secondary Button Value:"
                                placeholder="In href"
                                type="text"
                                value={data.btnSecondaryValue}
                                onChange={handleChange}
                                error={errors.btnSecondaryValue}
                            />
                        </div>

                        <div className="col-sm-4">
                            <FileUploader
                                id="image"
                                name="image"
                                value={data.image}
                                label="Image:"
                                multiple={false}
                                onChange={handleChange}
                                error={errors.image}
                            />
                            <span className="fw-semibold">Existing Image:</span>{" "}
                            <br />
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/${existingImageUrl}`}
                                alt=""
                                className="mt-2"
                                width={100}
                                height={100}
                            />
                        </div>
                        <div className="col-sm-1">
                            <Switch
                                id="isActive"
                                name="isActive"
                                label="Active Status: "
                                checked={data.isActive}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-3">
                            <Input
                                id="priority"
                                name="priority"
                                placeholder="Enter priority"
                                label="Priority:"
                                error={errors.priority}
                                type="number"
                                value={data.priority}
                                onChange={handleChange}
                            />
                        </div>
                        {previewUrl && (
                            <div className="col-sm-4">
                                <label htmlFor="image">
                                    <span className="fw-semibold">
                                        Image Preview:{" "}
                                    </span>
                                    <img
                                        src={previewUrl}
                                        alt=""
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </label>
                            </div>
                        )}
                        <div className="col-sm-4">
                            <Select
                                id="variant"
                                label="Variant:"
                                name="variant"
                                onChange={(value) => setVariant(value)}
                                data={[
                                    { value: "primary", label: "primary" },
                                    { value: "secondary", label: "secondary" },
                                ]}
                                error={errors.variant}
                                default={variant}
                            />
                            {errors.variant && (
                                <span className="d-inline-block text-danger mt-2">
                                    {errors.variant}
                                </span>
                            )}
                        </div>
                    </div>
                    <Button type="submit" label="Submit" variant="primary" />
                </form>
            </PageContent>
        </div>
    );
}

export default Edit;

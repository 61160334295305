import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { deletePage, getPages } from "api/request.api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";
import Swal from "sweetalert2";

function PageIndex() {
    const columns = useMemo(() => [
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Slug",
            accessor: "slug",
        },
        {
            Header: "Order",
            accessor: "order",
        },
        {
            Header: "Highlight",
            accessor: "highlight",
            Cell: ({ cell }) => (
                cell.value ? 'true' : 'false'
            )
        },
        {
            Header: "Actions",
            Cell: ({ row }) => (
                <>
                    <Button
                        type="button"
                        variant="info"
                        className="ms-2"
                        icon="bi bi-pencil-square"
                        onClick={() =>
                            navigate(`/admin/page/edit/${row.original.slug}`)
                        }
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="ms-2"
                        icon="bi bi-trash"
                        onClick={() => onDelete(row.original.slug)}
                    />
                </>
            ),
        },
    ]);

    const [pages, setPages] = useState([]);
    const navigate = useNavigate();

    async function fetchPages() {
        const { data } = await getPages();
        setPages(data);
    }

    const handleDeletePage = async (id) => {
        const data = await deletePage(id);
        if (data.success) {
            fetchPages();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this page?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeletePage(id);
            }
        });
    };

    useEffect(() => {
        fetchPages();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Page List" />
            <PageContent>
                <div className="d-flex flex-row-reverse">
                    <Link
                        className="btn btn-primary"
                        role="button"
                        to="/admin/page/create"
                    >
                        Create
                    </Link>
                </div>
                <Table columns={columns} data={pages} filter={true} />
            </PageContent>
        </div>
    );
}

export default PageIndex;

import Table from "components/Table";
import React, { useEffect, useMemo, useState } from "react";
import PageContent from "components/PageContent";
import PageHead from "components/PageHead";
import { getComments, deleteComment } from "api/request.api";
import { Button } from "components";
import Swal from "sweetalert2";

function CommentIndex() {  
    const columns = useMemo(() => [
        {
            Header: "Blog",
            accessor: "blogId.title",
        },
        {
            Header: "Full name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "message",
            accessor: "message",
            Cell: ({ cell }) =>
                cell.value ? cell.value.slice(0, 150) + "..." : "",
        },
        {
            Header: "Actions",
            accessor: "_id",
            Cell: ({ cell, row }) => (
                <>
                    <Button
                        type="button"
                        variant="primary"
                        className="mb-2"
                        icon="bi bi-eye"
                        onClick={() => handleViewComment(row.original.message)}
                    />
                    <Button
                        type="button"
                        variant="danger"
                        className="text-light"
                        icon="bi bi-trash"
                        onClick={() => onDelete(cell.value)}
                    />
                </>
            ),
        },
    ]);

    const [comments, setComments] = useState([]);

    // const getDate = (date) => {
    //     const desiredFormat = 'YYYY-MM-DD'; // Desired format
    //     return moment(date).format(desiredFormat);
    // }

    const handleViewComment = (message) => {
        Swal.fire({
            title: "Comment",
            html: message,
            showCloseButton: true,
            width: "600px",
        });
    };

    async function fetchComments() {
        const { data } = await getComments();
        setComments(data);
    }

    const handleDeleteComment = async (id) => {
        const data = await deleteComment(id);
        if (data.success) {
            fetchComments();
        }
    };

    const onDelete = (id) => {
        Swal.fire({
            title: `Do you want to delete this comment?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteComment(id);
            }
        });
    };

    useEffect(() => {
        fetchComments();
    }, []);

    return (
        <div className="p-4">
            <PageHead title="Comments List" />
            <PageContent>
                <Table columns={columns} data={comments} filter={true} />
            </PageContent>
        </div>
    );
}

export default CommentIndex;

import Joi from "joi";
import React, { Children, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Input } from "components";
import { login } from "api/request.api";

function Login() {
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const schema = Joi.object({
        email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
            .label("Email"),
        password: Joi.string().min(8).required().label("Password"),
    });

    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setData({
            ...data,
            [name]: value,
        });
        // Validate the updated field
        const fieldSchema = Joi.object({ [name]: schema.extract(name) });
        const { error } = fieldSchema.validate({ [name]: value });
        const updatedErrors = { ...errors };

        if (error) {
            updatedErrors[name] = error.details[0].message;
        } else {
            delete updatedErrors[name];
        }
        setErrors(updatedErrors);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const loginData = {
            type: "email",
            credentials: data,
        };

        try {
            const data = await login(loginData);
            if (data.success) {
                if (data.data.user.role === "client") {
                    toast.error("You are not allowed to login here");
                    setData({
                        email: "",
                        password: "",
                    });
                    return null;
                }
                localStorage.setItem("refreshToken", data.data.refresh_token);
                localStorage.setItem("accessToken", data.data.access_token);
                localStorage.setItem("user", JSON.stringify(data.data.user));
                localStorage.setItem("isLogged", true);

                navigate("/admin");
            } else {
                toast.error(data?.data?.message);
            }
        } catch (err) {
            console.log(err.response);
        }
    };
    return (
        <>
            <div className="container" style={{height: "100vh"}}>
                <div className="row justify-content-around align-self-center m-md-5 my-5">
                    <div className="col-md-6 col-12 p-md-5 py-2">
                        <div className="card p-md-5 p-5">
                            <h4 className="text-bold mb-3">Please login</h4>
                            <form
                                onSubmit={handleOnSubmit}
                                className="login"
                                autoComplete="new-password"
                            >
                                <Input
                                    id="email"
                                    name="email"
                                    label="Email Address:"
                                    type="text"
                                    value={data.email}
                                    onChange={handleChange}
                                    error={errors.email}
                                />
                                <Input
                                    id="password"
                                    name="password"
                                    label="Password:"
                                    type="password"
                                    value={data.password}
                                    onChange={handleChange}
                                    error={errors.password}
                                />

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={Object.keys(errors).length}
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;

import React from "react";

function Button(props) {
    return (
        <button
            onClick={props.type === "submit" ? null : props.onClick}
            type={props.type}
            title={props.title}
            className={`rounded-1 btn btn-${props.variant} ${props.className}`}
        >
            {props.children ?? (
                <>
                    <i className={props.icon}></i>
                    {props.label && <span className="ms-2">{props.label}</span>}
                </>
            )}
        </button>
    );
}

export default Button;

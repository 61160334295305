import { createBrowserRouter } from "react-router-dom";
import AdminLayout from "../layouts/Admin";
import adminRoute from "./admin";
import Login from "pages/auth/Login";

const route = createBrowserRouter([
    {
        path: "/",
        element: <AdminLayout />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/admin",
        element: <AdminLayout />,
        children: adminRoute,
    },
]);

export default route;

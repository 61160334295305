import Joi from "joi";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Input } from "components";
import { changePassword } from "api/request.api";
import changePasswordSchema from "schema/schema.changePassword";

function ChangePassword() {
    const [data, setData] = useState({
        currentPassword: "",
        newPassword: "",
        reNewPassword: "",
    });
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setData({
            ...data,
            [name]: value,
        });
        // Validate the updated field
        const fieldSchema = Joi.object({ [name]: changePasswordSchema.extract(name) });
        const { error } = fieldSchema.validate({ [name]: value });
        const updatedErrors = { ...errors };

        if (error) {
            updatedErrors[name] = error.details[0].message;
        } else {
            delete updatedErrors[name];
        }
        setErrors(updatedErrors);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (data.newPassword !== data.reNewPassword) {
            setErrors({
                ...errors,
                reNewPassword: "Passwords do not match",
            });
            return null;
        }
        try {
            const response = await changePassword({
                newPassword: data.newPassword,
                currentPassword: data.currentPassword,
            });
            if (response.success) {
                navigate("/admin");
            } else {
                toast.error(response?.data?.message);
            }
        } catch (err) {
            console.log(err.response);
        }
    };
    return (
        <>
            <div className="container">
                <div className="row justify-content-around">
                    <div className="col-md-6 col-sm-12 p-5">
                        <div className="card p-5">
                            <h4 className="text-bold mb-3">Change Password</h4>
                            <form
                                onSubmit={handleOnSubmit}
                                className="login"
                                autoComplete="new-password"
                            >
                                <Input
                                    id="currentPassword"
                                    name="currentPassword"
                                    label="Enter old password:"
                                    type="password"
                                    value={data.currentPassword}
                                    onChange={handleChange}
                                    error={errors.currentPassword}
                                />
                                <Input
                                    id="newPassword"
                                    name="newPassword"
                                    label="newPassword:"
                                    type="password"
                                    value={data.newPassword}
                                    onChange={handleChange}
                                    error={errors.newPassword}
                                />
                                <Input
                                    id="reNewPassword"
                                    name="reNewPassword"
                                    label="ReEnter new Password:"
                                    type="password"
                                    value={data.reNewPassword}
                                    onChange={handleChange}
                                    error={errors.reNewPassword}
                                />

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={Object.keys(errors).length}
                                >
                                    Change Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;

import Input from "components/Input";
import React, { useEffect, useRef, useState } from "react";

import FileUploader from "components/FileUploader";
import Editor from "components/Editor";
import { editBlog, getBlog } from "api/request.api";
import { useNavigate, useParams } from "react-router-dom";
import createForm from "helpers/createForm";
import { Button } from "components";
import validateField from "helpers/validateData";
import blogSchema from "schema/schema.blog";

const Joi = require("joi");
function Edit() {
    const [data, setData] = useState({
        thumbnail: "",
        title: "",
    });
    const [errors, setErrors] = useState({});

    const blogId = useParams().id;
    const navigate = useNavigate();

    const [previewUrl, setPreviewUrl] = useState("");
    const [existingImageUrl, setExistingUrl] = useState("");
    const editorRef = useRef(null);

    const handleChange = (e) => {
        const { name, type } = e.target;
        let { value } = e.target;

        if (type === "file" && value) {
            value = e.target.files[0];
            setPreviewUrl(URL.createObjectURL(value));
        }

        setData({
            ...data,
            [name]: value,
        });
    };

    useEffect(() => {
        if (previewUrl) {
            return () => URL.revokeObjectURL(previewUrl);
        }
    }, [previewUrl]);

    const fetchBlog = async () => {
        const { data } = await getBlog(blogId);

        setData({
            title: data.title,
            description: data.description,
        });

        setExistingUrl(data.thumbnail);
    };

    useEffect(() => {
        fetchBlog();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fieldErrors = validateField(
            blogSchema,
            {
                ...data,
                description: editorRef.current.getContent(),
            },
            ["thumbnail"]
        );
        setErrors(fieldErrors);

        if (Object.keys(fieldErrors).length > 0) {
            return null;
        }
        const formData = {
            ...data,
            description: editorRef.current.getContent(),
        };

        setData(formData);

        const result = await editBlog(blogId, createForm(formData));
        if (result.success) {
            navigate("/admin/blog");
        }
    };

    return (
        <div className="p-4">
            <section>
                <div className="row">
                    <div className="col-sm-6 mb-3">
                        <h4 className="fw-semibold">Edit Blog</h4>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="#">Blogs</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="p-4 bg-white rounded-3">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold my-0">Basic Information</h6>
                        <p>Fill all information below</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12">
                            <Input
                                id="title"
                                name="title"
                                placeholder="Enter the title"
                                label="Title:"
                                type="text"
                                value={data.title}
                                onChange={handleChange}
                                error={errors.title}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FileUploader
                                id="thumbnail"
                                name="thumbnail"
                                value={data.thumbnail}
                                label="Thumbnail:"
                                multiple={false}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            {previewUrl && (
                                <>
                                    <span className="fw-semibold">
                                        Preview Thumbnail
                                    </span>
                                    <br />
                                    <img
                                        src={previewUrl}
                                        height={100}
                                        width={100}
                                        className="mt-2"
                                    />
                                </>
                            )}
                        </div>
                        <div className="col-sm-6 mb-3">
                            <span className="fw-semibold">
                                Existing Thumbnail:
                            </span>
                            <br />
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/${existingImageUrl}`}
                                height={100}
                                width={100}
                                className="mt-2"
                            />
                        </div>
                        <div className="col-sm-12 mb-3">
                            <Editor
                                id="description"
                                name="description"
                                label="Description:"
                                editorRef={editorRef}
                                initialValue={data.description}
                            />
                            {errors.description && (
                                <span className="d-inline-block text-danger mt-2">
                                    {errors.description}
                                </span>
                            )}
                        </div>
                    </div>
                    <Button
                        type="submit"
                        label="Save Changes"
                        variant="primary"
                    />
                </form>
            </section>
        </div>
    );
}

export default Edit;

import React from "react";
import logo from "assets/images/logo.png";

function OrderInvoice({ order, getDate }) {
    return (
        <>
            <div className="d-flex justify-content-between">
                <h3>INVOICE</h3>
                <img src={logo} alt="milushree" width="100" />
            </div>

            <div className="my-2 text-start">
                <span className="fw-semibold">
                    {order.billingAddress.firstname}{" "}
                    {order.billingAddress.lastname}
                </span>
                <br />
                <span className="text-muted">{order.email}</span> <br />
            </div>

            <div
                className="row my-3 justify-content-between gx-0"
                style={{ textAlign: "left" }}
            >
                <div className="col-md-3 col-12">
                    <span className="fw-semibold">Bill To</span>
                    <br />
                    <p className="fw-normal">
                        {order.billingAddress.firstname}{" "}
                        {order.billingAddress.lastname}
                        <br />
                        {order.billingAddress.streetAddress}
                        <br />
                        {order.billingAddress.town},{" "}
                        {order.billingAddress.state} {order.billingAddress.zip}
                    </p>
                </div>
                <div className="col-md-3  col-12">
                    <span className="fw-semibold">Ship To</span>
                    <br />
                    <p className="fw-normal">
                        {order.shippingAddress.firstname}{" "}
                        {order.shippingAddress.lastname}
                        <br />
                        {order.shippingAddress.streetAddress} <br />
                        {order.shippingAddress.town},{" "}
                        {order.shippingAddress.state}{" "}
                        {order.shippingAddress.zip}
                    </p>
                </div>
                <div className="col-md-5  col-12">
                    <table className="table table-borderless">
                        <tr>
                            <th className="p-0">Order #</th>
                            <td className="text-end p-0">{order._id}</td>
                        </tr>
                        <tr>
                            <th className="p-0">Ordered Date</th>
                            <td className="text-end p-0">
                                {getDate(order.createdAt)}
                            </td>
                        </tr>
                        <tr>
                            <th className="p-0">Payment Method</th>
                            <td className="text-end p-0">
                                {order.paymentMethod.type}
                            </td>
                        </tr>
                        <tr>
                            <th className="p-0">Shipping Status</th>
                            <td className="text-end p-0">{order.shippingStatus}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <table className="table align-middle">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Image</th>
                        <th>Size</th>
                        <th>Quantity</th>
                        <th>Unit Price($)</th>
                        <th>Amount($)</th>
                    </tr>
                </thead>
                <tbody>
                    {order.products.map((product) => (
                        <tr key={product?._id}>
                            <td>{product.name}</td>
                            <td>
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${product.thumbnail}`}
                                    alt={product.name}
                                    width="60"
                                />
                            </td>
                            <td style={{ width: "200px" }}>
                                {`${product.size.depth} * ${product.size.width} * ${product.size.height}`}
                            </td>
                            <td>{product.quantity}</td>
                            <td>{product.offerPrice || product.price}</td>
                            <td>
                                {(product.offerPrice || product.price) *
                                    product.quantity}
                            </td>
                        </tr>
                    ))}

                    <tr>
                        <th colspan="5" className="text-end">
                            SubTotal
                        </th>
                        <td>{order.subTotal}</td>
                    </tr>
                    <tr>
                        <th colspan="5" className="text-end">
                            Discount Amount
                        </th>
                        <td>{order.subTotal - order.grandTotal}</td>
                    </tr>
                    <tr>
                        <th colspan="5" className="text-end">
                            Total
                        </th>
                        <td>{order.grandTotal}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default OrderInvoice;
